import React from 'react'

const Otp = () => {
  return (
    <>
    otp
    </>
  )
}

export default Otp