import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";
import reportWebVitals from './reportWebVitals';
import { BrowserRouter  } from "react-router-dom";
import ScrollToTop from './componet/ScrollToTop';

import { CartProvider } from "react-use-cart";



const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
 
  <BrowserRouter basename={baseUrl}>
    <ScrollToTop />
    <CartProvider>
    <App />
    </CartProvider>
  </BrowserRouter>
 
);


reportWebVitals();