import React from "react";
import Footer from "./Footer";
import Header from "./Header";
import ServiceArea from "./ServiceArea";
import useRazorpay from "react-razorpay";
import { useState, useEffect } from "react";
import Slider from "react-slick";
import { Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useCart } from "react-use-cart";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { Image } from "react-img-placeholder";
import "react-lazy-load-image-component/src/effects/blur.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import PlaceholderImage from "../placeholder.png";

export default function Home() {
  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();
  const [IsImageLoaded, setIsImageLoaded] = useState(false);
  const { addItem } = useCart();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const renderHTML = (rawHTML) =>
    React.createElement("div", {
      dangerouslySetInnerHTML: { __html: rawHTML },
    });

  const addNewItem = (item) => {
    const userDetail = JSON.parse(localStorage.getItem("user"));

    if (userDetail) {
      navigate("/CheckOut");
    } else {
      navigate("/SignIn");
    }
    NotificationManager.success(
      "Item added successfully",
      "Item added successfully"
    );
    addItem(item);
  };

  const [data, setData] = useState([]);
  const [alt_image, setAlterImage] = useState([]);
  const { id } = useParams();

  const url = "http://jnkitsolutions.com/findla-backend/api.php?service=product_details&id=";

  useEffect(() => {
    fetch(url + id)
      .then((response) => response.json())
      .then((json) => {
        setData(json);
        setAlterImage(json.alt_image);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  }, []);
  const settings = {
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 4000,
    cssEase: "ease-in",
  };


  return (
    <>
      <Header />
      {loading ? (
        <div className="loader-containers">
          <div className="spinner"></div>
        </div>
      ) : (
        <main className="main-wrapper">
          <div className="axil-single-product-area bg-color-white">
            <div className="single-product-thumb axil-section-gap ">
              <div className="container">
                <div className="row row--50">
                  <div className="col-lg-6 mb--40">
                    <div className="position-sticky ">
                      <div className="row">
                        {alt_image.length > 0 && (
                          <div className="col-lg-12">
                            <div className="product-large-thumbnail-2 single-product-thumbnail axil-product slick-layout-wrapper--15 axil-slick-arrow">
                              <Slider
                                {...settings}
                                asNavFor={nav2}
                                ref={(slider1) => setNav1(slider1)}
                              >
                                {alt_image.map((item) => (
                                  <div className="thumbnaiddl">
                                    <img
                                      src={item.image_url}
                                      PlaceholderSrc={PlaceholderImage}
                                      width={250}
                                      height={250}
                                      // PlaceholderSrc={PlaceholderImage}
                                      effect="blur"
                                    />
                                  </div>
                                ))}
                              </Slider>
                            </div>
                          </div>
                        )}
                        {alt_image.length > 0 && (
                          <div className="col-lg-12 ">
                            <div className="small-thumb-wrapper product-small-thumb-2 small-thumb-style-two small-thumb-style-three">
                              <Slider
                                {...settings}
                                asNavFor={nav1}
                                ref={(slider2) => setNav2(slider2)}
                                slidesToShow={alt_image.length}
                                swipeToSlide={true}
                                focusOnSelect={true}
                              >
                                {alt_image.map((image) => (
                                  <div className="small-thumb-img">
                                    <img
                                      src={image.image_url}
                                      alt="samll-thumb"
                                    />
                                  </div>
                                ))}
                              </Slider>
                            </div>
                          </div>
                        )}
                        {alt_image.length <= 0 && (
                          <div className="col-lg-12 ">
                            <div className="product-large-thumbnail-2 single-product-thumbnail axil-product slick-layout-wrapper--15 axil-slick-arrow">
                              <Slider
                                {...settings}
                                asNavFor={nav2}
                                ref={(slider1) => setNav1(slider1)}
                              >
                                <div className="thumbnaiddl">
                                  <img
                                    src={data.image_url}
                                    alt="Product Images"
                                  />
                                </div>
                              </Slider>
                            </div>
                          </div>
                        )}
                        {alt_image.length <= 0 && (
                          <div className="col-lg-6">
                            <div className="small-thumb-wrapper product-small-thumb-2 small-thumb-style-two small-thumb-style-three">
                              <Slider
                                {...settings}
                                asNavFor={nav1}
                                focusOnSelect={true}
                              >
                                <div className="small-thumb-img">
                                  <img src={data.image_url} alt="samll-thumb" />
                                </div>
                              </Slider>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 mb--40">
                    <div className="h-100">
                      <div className="position-sticky sticky-top">
                        <div className="single-product-content nft-single-product-content">
                          <div className="inner">
                            <h2 className="product-title">{data.name}</h2>
                            <div className="price-amount price-offer-amount">
                              <span className="price current-price">
                                Rs.{data.sell_price}
                              </span>
                            </div>

                            <li className="add-to-cart">
                              <span style={{ display: "none" }}>
                                {(data.price = data.sell_price)}
                              </span>

                              <div className="divs">
                                <div className="container">
                                  <div className="row">
                                    <div className="col-lg-6 col-12">
                                      <Link to="/Productview/:id">
                                        <button
                                          className="axil-btn btn-bg-primary"
                                          onClick={() => addNewItem(data)}
                                        >
                                          Add cart
                                        </button>
                                      </Link>
                                    </div>
                                    <div className="col-lg-6 col-12">
                                      <Link to="/CheckOut"></Link>
                                      <button
                                        className="axil-btn btn-bg-primary mmmm"
                                        onClick={() => addNewItem(data)}
                                      >
                                        Buy Product
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </li>

                            <div className="nft-short-meta">
                              <div className="row align-items-center">
                                <div className="col-md-6">
                                  <div className="nft-category">
                                    <label>Category :</label>
                                    <div className="category-list">
                                      {data.category_name}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6"></div>
                              </div>
                            </div>

                            <div className="woocommerce-tabs wc-tabs-wrapper bg-vista-white nft-info-tabs">
                              <div className="container">
                                <ul
                                  className="nav tabs"
                                  id="myTab"
                                  role="tablist"
                                >
                                  <li className="nav-item" role="presentation">
                                    <a
                                      className="active"
                                      id="description-tab"
                                      data-bs-toggle="tab"
                                      href="#description"
                                      role="tab"
                                      aria-controls="description"
                                      aria-selected="true"
                                    >
                                      Description
                                    </a>
                                  </li>
                                  <li className="nav-item " role="presentation">
                                    <a
                                      id="additional-info-tab"
                                      data-bs-toggle="tab"
                                      href="#additional-info"
                                      role="tab"
                                      aria-controls="additional-info"
                                      aria-selected="false"
                                    >
                                      Feature
                                    </a>
                                  </li>
                                  <li className="nav-item" role="presentation">
                                    <a
                                      id="reviews-tab"
                                      data-bs-toggle="tab"
                                      href="#reviews"
                                      role="tab"
                                      aria-controls="reviews"
                                      aria-selected="false"
                                    >
                                      ingredient{" "}
                                    </a>
                                  </li>
                                </ul>
                                <div className="tab-content" id="myTabContent">
                                  <div
                                    className="tab-pane fade show active"
                                    id="description"
                                    role="tabpanel"
                                    aria-labelledby="description-tab"
                                  >
                                    <div className="product-additional-info">
                                      {renderHTML(data.descr)}
                                    </div>
                                  </div>
                                  <div
                                    className="tab-pane fade"
                                    id="additional-info"
                                    role="tabpanel"
                                    aria-labelledby="additional-info-tab"
                                  >
                                    <div className="product-additional-info">
                                      {renderHTML(data.feature)}
                                    </div>
                                  </div>
                                  <div
                                    className="tab-pane fade"
                                    id="reviews"
                                    role="tabpanel"
                                    aria-labelledby="reviews-tab"
                                  >
                                    <div className="product-additional-info"></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      )}
      <ServiceArea />
      <NotificationContainer />
      <Footer />
    </>
  );
}
