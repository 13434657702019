import React from 'react'
import Footer from './Footer'
import Header from './Header'
import ServiceArea from './ServiceArea'
import { useState, useEffect } from "react";
import { Link } from 'react-router-dom'
import Accordion from 'react-bootstrap/Accordion';
const Faq = () => {
  const [data,setData]=useState([]);
  const [loading, setLoading] = useState(false);
  const renderHTML = (rawHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });
  const url = "http://jnkitsolutions.com/findla-backend/api.php?service=faq_list";
  useEffect(()=>{
    fetch(url)
    .then(response=>response.json()).then(json=>{
      setData(json.FAQ);
      setLoading(false);
    }).catch(e=>{
      setLoading(false);
    })
  },[])
  return (
    <>
    <Header/>
    <div className="axil-breadcrumb-area">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-8">
                        <div className="inner">
                            <ul className="axil-breadcrumb">
                                <li className="axil-breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="separator"></li>
                                <li className="axil-breadcrumb-item active" aria-current="page">FAQ</li>
                            </ul>
                            <h1 className="title">FAQ</h1>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-4">
                        <div className="inner">
                            <div className="bradcrumb-thumb">
                                <img src="images/product-45.png" alt="Image" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    <div className="container ffsd">
    <Accordion defaultActiveKey="0">
      
      {
          data.map((faq)=> {
          return (
      <Accordion.Item eventKey={faq.id}>
        <Accordion.Header fffs >{faq.subject}</Accordion.Header>
        <Accordion.Body>
          {renderHTML(faq.description)}
        </Accordion.Body>
      </Accordion.Item>
      )
          })
          }

    </Accordion>
</div>
  <ServiceArea/>
   <Footer/>
    </>
  )
}

export default Faq