import React from 'react'
import '../App.js'

import Footer from './Footer'
import useRazorpay from "react-razorpay";
const Chek = () => {
    const Razorpay = useRazorpay();

const handlePayment = async () => {

  const options = {
    key: "rzp_test_a2XcLfU0dyrZ7m", // Enter the Key ID generated from the Dashboard
    amount: "50000", // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
    currency: "INR",
    name: "Jaydip Makwana",
    description: "Demo",
    image: "https://new.axilthemes.com/demo/template/etrade/assets/images/logo/logo.png",
    handler: function (response) {
      alert(response.razorpay_payment_id);
            },
            prefill: {
            name: "Jaydeep Makwana",
            email: "demo@yopmail.com",
            contact: "9723037928",
            },
            notes: {
            address: "ahmedabad",
            },
            theme: {
            color: "#3399cc",
            },
        };

        const rzp1 = new Razorpay(options);
        rzp1.on("payment.failed", function (response) {
            alert(response.error.code);
        });
        rzp1.open();
        };


  return (
    <>
    <a href="#top" className="back-to-top" id="backto-top"><i className="fal fa-arrow-up"></i></a>
  
   

    <main className="main-wrapper">
        
        <div className="axil-single-product-area bg-color-white">
            <div className="single-product-thumb axil-section-gap pb--30 pb_sm--20">
                <div className="container">
                    <div className="row row--50">
                        <div className="col-lg-6 mb--40">
                            <div className="h-100">
                                <div className="position-sticky sticky-top">
                                    <div className="single-product-thumbnail axil-product">
                                        <div className="thumbnail">
                                            <img src="images/product-10.png" alt="Product Images"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 mb--40">
                            <div className="h-100">
                                <div className="position-sticky sticky-top">
                                    <div className="single-product-content nft-single-product-content">
                                        <div className="inner">
                                            <h2 className="product-title">Bored Ape Yacht Club</h2>
                                            <div className="price-amount price-offer-amount">
                                                <span className="price current-price">$5000.00</span>
                                            </div>

                                           
                                            <div className="product-action-wrapper d-flex-center">

                                                
                                                <ul className="product-action action-style-two d-flex-center mb--0">
                                                    <li className="add-to-cart"><a href="#" onClick={handlePayment} className="axil-btn btn-bg-primary">Buy Product</a></li>
                                                </ul>
                                               
                                            </div>

                                            <div className="nft-short-meta">
                                                <div className="row align-items-center">
                                                    <div className="col-md-6">
                                                        <div className="nft-category">
                                                            <label>Category :</label>
                                                            <div className="category-list">
                                                                <a href="#">Digital Art</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="nft-verified-option">
                                                            <label>Is this item Authentic?</label>
                                                            <button className="verify-btn axil-btn btn-bg-secondary">Verifiy</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="woocommerce-tabs wc-tabs-wrapper bg-vista-white nft-info-tabs">
                                                <div className="container">
                                                    <ul className="nav tabs" id="myTab" role="tablist">
                                                        <li className="nav-item" role="presentation">
                                                            <a className="active" id="description-tab" data-bs-toggle="tab" href="#description" role="tab" aria-controls="description" aria-selected="true">Description</a>
                                                        </li>
                                                        <li className="nav-item " role="presentation">
                                                            <a id="additional-info-tab" data-bs-toggle="tab" href="#additional-info" role="tab" aria-controls="additional-info" aria-selected="false">Additional Information</a>
                                                        </li>
                                                        <li className="nav-item" role="presentation">
                                                            <a id="reviews-tab" data-bs-toggle="tab" href="#reviews" role="tab" aria-controls="reviews" aria-selected="false">Property</a>
                                                        </li>
                                                    </ul>
                                                    <div className="tab-content" id="myTabContent">
                                                        <div className="tab-pane fade show active" id="description" role="tabpanel" aria-labelledby="description-tab">
                                                            <div className="product-additional-info">
                                                                <p className="mb--15"><strong>About this Product</strong></p>
                                                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Veritatis culpa error modi tenetur ratione inventore ipsam voluptate praesentium quod nemo.</p>
                                                                <div className="table-responsive">
                                                                    <table>
                                                                        <tbody>
                                                                            <tr>
                                                                                <th>Contract Address</th>
                                                                                <td>0xd4fio...se520</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>Token ID</th>
                                                                                <td>420</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>Token Standard</th>
                                                                                <td>Bna-420</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>Blockchain</th>
                                                                                <td>Ethereum</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="tab-pane fade" id="additional-info" role="tabpanel" aria-labelledby="additional-info-tab">
                                                            <div className="product-additional-info">
                                                                <div className="table-responsive">
                                                                    <table>
                                                                        <tbody>
                                                                            <tr>
                                                                                <th>Brand</th>
                                                                                <td>eTrade</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>Collection </th>
                                                                                <td>eTrade Capsules</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>Creator</th>
                                                                                <td>Arnold Ray</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>Gen</th>
                                                                                <td>1</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>Rarity </th>
                                                                                <td>Epic</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>Type</th>
                                                                                <td>Digital Art</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="tab-pane fade" id="reviews" role="tabpanel" aria-labelledby="reviews-tab">
                                                            <div className="product-additional-info">
                                                                <div className="table-responsive">
                                                                    <table>
                                                                        <tbody>
                                                                            <tr>
                                                                                <th>Brand</th>
                                                                                <td>eTrade</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>Collection </th>
                                                                                <td>eTrade Capsules</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>Creator</th>
                                                                                <td>Arnold Ray</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>Gen</th>
                                                                                <td>1</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>Rarity </th>
                                                                                <td>Epic</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>Type</th>
                                                                                <td>Digital Art</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
        
        
      <div className="pro">
      
<div className="left " >
<img src="images/ear1.png"  />
<h3 className='left1'>Immersive Sound</h3>
<p className='right1'>Its 10mm dynamic drivers pumps out enthralling auditory experience.</p>
</div>
<div className="Right">
<h2 className='right2'>Fast Charge Technology</h2>
<p className='right2'>The Fast Charging technology ensures that you get 4 hours of charge in just 20 minutes while charging. Playback time of 8 hours lets you delve deeper while you Plug Into Nirvana with Rockerz 235V2.</p>
<img src="images/ear.png"  />
</div>
      </div>
      <div className="new">
      <div className="container">
      <div className="row">
      <div className="col-lg-6">
      <img src="images/ear3.png" className='imggd'/>
      </div>
      <div className="col-lg-6">
      <h2 className='mm'>One Press Voice Assistant</h2>
      <p className='kk'> It is equipped with easy access integrated controls with one press Voice Assistant. The headset offers call vibration alert to the users for a superior hands-free experience.</p>
      </div>
      </div>
      </div>
      </div>
      
     
    </main>


    <div className="service-area">
        <div className="container">
            <div className="row row-cols-xl-4 row-cols-sm-2 row-cols-1 row--20">
                <div className="col">
                    <div className="service-box service-style-2">
                        <div className="icon">
                            <img src="images/service1.png" alt="Service"/>
                        </div>
                        <div className="content">
                            <h6 className="title">Fast &amp; Secure Delivery</h6>
                            <p>Tell about your service.</p>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="service-box service-style-2">
                        <div className="icon">
                            <img src="images/service2.png" alt="Service"/>
                        </div>
                        <div className="content">
                            <h6 className="title">Money Back Guarantee</h6>
                            <p>Within 10 days.</p>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="service-box service-style-2">
                        <div className="icon">
                            <img src="images/service3.png" alt="Service"/>
                        </div>
                        <div className="content">
                            <h6 className="title">24 Hour Return Policy</h6>
                            <p>No question ask.</p>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="service-box service-style-2">
                        <div className="icon">
                            <img src="images/service4.png" alt="Service"/>
                        </div>
                        <div className="content">
                            <h6 className="title">Pro Quality Support</h6>
                            <p>24/7 Live support.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
   
   <Footer/>
   
    <div className="modal fade quick-view-product" id="quick-view-modal" tabindex="-1" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
                <div className="modal-header">
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"><i className="far fa-times"></i></button>
                </div>
                <div className="modal-body">
                    <div className="single-product-thumb">
                        <div className="row">
                            <div className="col-lg-7 mb--40">
                                <div className="row">
                                    <div className="col-lg-10 order-lg-2">
                                        <div className="single-product-thumbnail product-large-thumbnail axil-product thumbnail-badge zoom-gallery">
                                            <div className="thumbnail">
                                                <img src="images/product-big-01.png" alt="Product Images"/>
                                                <div className="label-block label-right">
                                                    <div className="product-badget">20% OFF</div>
                                                </div>
                                                <div className="product-quick-view position-view">
                                                    <a href="assets/images/product/product-big-01.png" className="popup-zoom">
                                                        <i className="far fa-search-plus"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="thumbnail">
                                                <img src="images/product-big-02.png" alt="Product Images"/>
                                                <div className="label-block label-right">
                                                    <div className="product-badget">20% OFF</div>
                                                </div>
                                                <div className="product-quick-view position-view">
                                                    <a href="assets/images/product/product-big-02.png" className="popup-zoom">
                                                        <i className="far fa-search-plus"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="thumbnail">
                                                <img src="images/product-big-03.png" alt="Product Images"/>
                                                <div className="label-block label-right">
                                                    <div className="product-badget">20% OFF</div>
                                                </div>
                                                <div className="product-quick-view position-view">
                                                    <a href="assets/images/product/product-big-03.png" className="popup-zoom">
                                                        <i className="far fa-search-plus"></i>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-2 order-lg-1">
                                        <div className="product-small-thumb small-thumb-wrapper">
                                            <div className="small-thumb-img">
                                                <img src="images/thumb-08.png" alt="thumb image"/>
                                            </div>
                                            <div className="small-thumb-img">
                                                <img src="images/thumb-07.png" alt="thumb image"/>
                                            </div>
                                            <div className="small-thumb-img">
                                                <img src="images/thumb-09.png" alt="thumb image"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5 mb--40">
                                <div className="single-product-content">
                                    <div className="inner">
                                        <div className="product-rating">
                                            <div className="star-rating">
                                                <img src="images/rate.png" alt="Rate Images"/>
                                            </div>
                                            <div className="review-link">
                                                <a href="#">(<span>1</span> customer reviews)</a>
                                            </div>
                                        </div>
                                        <h3 className="product-title">Serif Coffee Table</h3>
                                        <span className="price-amount">$155.00 - $255.00</span>
                                        <ul className="product-meta">
                                            <li><i className="fal fa-check"></i>In stock</li>
                                            <li><i className="fal fa-check"></i>Free delivery available</li>
                                            <li><i className="fal fa-check"></i>Sales 30% Off Use Code: MOTIVE30</li>
                                        </ul>
                                        <p className="description">In ornare lorem ut est dapibus, ut tincidunt nisi pretium. Integer ante est, elementum eget magna. Pellentesque sagittis dictum libero, eu dignissim tellus.</p>

                                        <div className="product-variations-wrapper">

                                            
                                            <div className="product-variation">
                                                <h6 className="title">Colors:</h6>
                                                <div className="color-variant-wrapper">
                                                    <ul className="color-variant mt--0">
                                                        <li className="color-extra-01 active"><span><span className="color"></span></span>
                                                        </li>
                                                        <li className="color-extra-02"><span><span className="color"></span></span>
                                                        </li>
                                                        <li className="color-extra-03"><span><span className="color"></span></span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            
                                            <div className="product-variation">
                                                <h6 className="title">Size:</h6>
                                                <ul className="range-variant">
                                                    <li>xs</li>
                                                    <li>s</li>
                                                    <li>m</li>
                                                    <li>l</li>
                                                    <li>xl</li>
                                                </ul>
                                            </div>
                                           

                                        </div>

                                       
                                        <div className="product-action-wrapper d-flex-center">
                                           
                                            <div className="pro-qty"><input type="text" value="1"/></div>
                                            
                                            <ul className="product-action d-flex-center mb--0">
                                                <li className="add-to-cart"><a href="cart.html" className="axil-btn btn-bg-primary">Add to Cart</a></li>
                                                <li className="wishlist"><a href="wishlist.html" className="axil-btn wishlist-btn"><i className="far fa-heart"></i></a></li>
                                            </ul>
                                         
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div className="header-search-modal" id="header-search-modal">
        <button className="card-close sidebar-close"><i className="fas fa-times"></i></button>
        <div className="header-search-wrap">
            <div className="card-header">
                <form action="#">
                    <div className="input-group">
                        <input type="search" className="form-control" name="prod-search" id="prod-search" placeholder="Write Something...."/>
                        <button type="submit" className="axil-btn btn-bg-primary"><i className="far fa-search"></i></button>
                    </div>
                </form>
            </div>
            <div className="card-body">
                <div className="search-result-header">
                    <h6 className="title">24 Result Found</h6>
                    <a href="shop.html" className="view-all">View All</a>
                </div>
                <div className="psearch-results">
                    <div className="axil-product-list">
                        <div className="thumbnail">
                            <a href="single-product.html">
                                <img src="images/product-09.png" alt="Yantiti Leather Bags"/>
                            </a>
                        </div>
                        <div className="product-content">
                            <div className="product-rating">
                                <span className="rating-icon">
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                                <i className="fal fa-star"></i>
                            </span>
                                <span className="rating-number"><span>100+</span> Reviews</span>
                            </div>
                            <h6 className="product-title"><a href="single-product.html">Media Remote</a></h6>
                            <div className="product-price-variant">
                                <span className="price current-price">$29.99</span>
                                <span className="price old-price">$49.99</span>
                            </div>
                            <div className="product-cart">
                                <a href="cart.html" className="cart-btn"><i className="fal fa-shopping-cart"></i></a>
                                <a href="wishlist.html" className="cart-btn"><i className="fal fa-heart"></i></a>
                            </div>
                        </div>
                    </div>
                    <div className="axil-product-list">
                        <div className="thumbnail">
                            <a href="single-product.html">
                                <img src="images/product-09.png" alt="Yantiti Leather Bags"/>
                            </a>
                        </div>
                        <div className="product-content">
                            <div className="product-rating">
                                <span className="rating-icon">
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                                <i className="fal fa-star"></i>
                            </span>
                                <span className="rating-number"><span>100+</span> Reviews</span>
                            </div>
                            <h6 className="product-title"><a href="single-product.html">Media Remote</a></h6>
                            <div className="product-price-variant">
                                <span className="price current-price">$29.99</span>
                                <span className="price old-price">$49.99</span>
                            </div>
                            <div className="product-cart">
                                <a href="cart.html" className="cart-btn"><i className="fal fa-shopping-cart"></i></a>
                                <a href="wishlist.html" className="cart-btn"><i className="fal fa-heart"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    


    <div className="cart-dropdown" id="cart-dropdown">
        <div className="cart-content-wrap">
            <div className="cart-header">
                <h2 className="header-title">Cart review</h2>
                <button className="cart-close sidebar-close"><i className="fas fa-times"></i></button>
            </div>
            <div className="cart-body">
                <ul className="cart-item-list">
                    <li className="cart-item">
                        <div className="item-img">
                            <a href="single-product.html"><img src="images/product-01.png" alt="Commodo Blown Lamp"/></a>
                            <button className="close-btn"><i className="fas fa-times"></i></button>
                        </div>
                        <div className="item-content">
                            <div className="product-rating">
                                <span className="icon">
								<i className="fas fa-star"></i>
								<i className="fas fa-star"></i>
								<i className="fas fa-star"></i>
								<i className="fas fa-star"></i>
								<i className="fas fa-star"></i>
							</span>
                                <span className="rating-number">(64)</span>
                            </div>
                            <h3 className="item-title"><a href="single-product-3.html">Wireless PS Handler</a></h3>
                            <div className="item-price"><span className="currency-symbol">$</span>155.00</div>
                            <div className="pro-qty item-quantity">
                                <input type="number" className="quantity-input" value="15"/>
                            </div>
                        </div>
                    </li>
                    <li className="cart-item">
                        <div className="item-img">
                            <a href="single-product-2.html"><img src="images/product-02.png" alt="Commodo Blown Lamp"/></a>
                            <button className="close-btn"><i className="fas fa-times"></i></button>
                        </div>
                        <div className="item-content">
                            <div className="product-rating">
                                <span className="icon">
								<i className="fas fa-star"></i>
								<i className="fas fa-star"></i>
								<i className="fas fa-star"></i>
								<i className="fas fa-star"></i>
								<i className="fas fa-star"></i>
							</span>
                                <span className="rating-number">(4)</span>
                            </div>
                            <h3 className="item-title"><a href="single-product-2.html">Gradient Light Keyboard</a></h3>
                            <div className="item-price"><span className="currency-symbol">$</span>255.00</div>
                            <div className="pro-qty item-quantity">
                                <input type="number" className="quantity-input" value="5"/>
                            </div>
                        </div>
                    </li>
                    <li className="cart-item">
                        <div className="item-img">
                            <a href="single-product-3.html"><img src="images/product-03.png" alt="Commodo Blown Lamp"/></a>
                            <button className="close-btn"><i className="fas fa-times"></i></button>
                        </div>
                        <div className="item-content">
                            <div className="product-rating">
                                <span className="icon">
								<i className="fas fa-star"></i>
								<i className="fas fa-star"></i>
								<i className="fas fa-star"></i>
								<i className="fas fa-star"></i>
								<i className="fas fa-star"></i>
							</span>
                                <span className="rating-number">(6)</span>
                            </div>
                            <h3 className="item-title"><a href="single-product.html">HD CC Camera</a></h3>
                            <div className="item-price"><span className="currency-symbol">$</span>200.00</div>
                            <div className="pro-qty item-quantity">
                                <input type="number" className="quantity-input" value="100"/>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div className="cart-footer">
                <h3 className="cart-subtotal">
                    <span className="subtotal-title">Subtotal:</span>
                    <span className="subtotal-amount">$610.00</span>
                </h3>
                <div className="group-btn">
                    <a href="cart.html" className="axil-btn btn-bg-primary viewcart-btn">View Cart</a>
                    <a href="checkout.html" className="axil-btn btn-bg-secondary checkout-btn">Checkout</a>
                </div>
            </div>
        </div>
    </div>
    
    </>
  )
}

export default Chek