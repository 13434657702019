import React from 'react'
import AxilNewsletter from './AxilNewsletter'
import Footer from './Footer'
import Header from './Header'
import ServiceArea from './ServiceArea'
import Slider from 'react-slick'
import { Link } from 'react-router-dom';
export default function Aboutas() {

    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };


  return (
   <>
    <Header />
    <main className="main-wrapper">
        {/* <!-- Start Breadcrumb Area  --> */}
        <div className="axil-breadcrumb-area">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-8">
                        <div className="inner">
                            <ul className="axil-breadcrumb">
                                <li className="axil-breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="separator"></li>
                                <li className="axil-breadcrumb-item active" aria-current="page">About Us</li>
                            </ul>
                            <h1 className="title">About Our Store</h1>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-4">
                        <div className="inner">
                            <div className="bradcrumb-thumb">
                            <img src="images/product-45.png" alt="images" />
                            
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- End Breadcrumb Area  --> */}

        {/* <!-- Start About Area  --> */}
        {/* <div className="axil-about-area about-style-1 axil-section-gap ">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-xl-4 col-lg-6">
                        <div className="about-thumbnail">
                            <div className="thumbnail">
                                <img src="images/about-01.png" alt="About Us" />
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-8 col-lg-6">
                        <div className="about-content content-right">
                            <span className="title-highlighter highlighter-primary2"> <i className="far fa-shopping-basket"></i>Our Story</span>
                            <h3 className="title">Online shopping includes both buying things online.</h3>
                            <span className="text-heading">The SHPERO Health LLP was founded in 2017 with a vision to build the most sustainable system in the area of Healthcare & Agriculture and to develop products from the multifarious xerophytes that focus more on <b>“Waste to Value”</b> and <b>“Sustainable Development”.</b></span>
                            <div className="row">
                                <div className="col-xl-6">
                                    <p>Empower your sales teams with industry tailored
                                        solutions that support manufacturers as they go
                                        digital, and adapt to changing markets & customers
                                        faster by creating new business.</p>
                                </div>
                                <div className="col-xl-6">
                                    <p className="mb--0">Salesforce B2B Commerce offers buyers the
                                        seamless, self-service experience of online
                                        shopping with all the B2B</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> */}
       
        {/* <div className="about-info-area">
            <div className="container">
                <div className="row row--20">
                    <div className="col-lg-4">
                        <div className="about-info-box">
                            <div className="thumb">
                            
                                <img src="images/shape-01.png" alt="Shape" />
                            </div>
                            <div className="content">
                                <h6 className="title">40,000+ Happy Customer</h6>
                                <p>Empower your sales teams with industry
                                    tailored solutions that support.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="about-info-box">
                            <div className="thumb">
                                <img src="images/shape-02.png" alt="Shape" />
                            </div>
                            <div className="content">
                                <h6 className="title">16 Years of Experiences</h6>
                                <p>Empower your sales teams with industry
                                    tailored solutions that support.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="about-info-box">
                            <div className="thumb">
                                <img src="images/shape-03.png" alt="Shape" />
                            </div>
                            <div className="content">
                                <h6 className="title">12 Awards Won</h6>
                                <p>Empower your sales teams with industry
                                    tailored solutions that support.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> */}
        {/* <!-- End About Area  --> */}    


        {/* <!-- Start About Area  --> */}
        
            <div className="team-left-fullwidth">
                <div className="container ml--xxl-0">
                    <div className="section-title-wrapper">
{/*
                        <span className="title-highlighter highlighter-primary"> <i className="fas fa-users"></i> Our Team</span>
                        <h3 className="title">Expart Management Team</h3>
*/}
                    </div>
                    <div className="team-slide-activation slick-layout-wrapper--20 axil-slick-arrow  arrow-top-slide">
                   <div className="container">
                   
                    </div>
                    </div>
                </div>
            
        </div>
        {/* <!-- End About Area  --> */}


        {/* <!-- Start About Area  --> */}
        <div className="axil-about-area about-style-2" style={{paddingBottom:"80px"}}>
            <div className="container">
                <div className="row align-items-center mb--80 mb_sm--60">
                    <div className="col-lg-5">
                        <div className="about-thumbnail">
                            <img src="images/about-02.png" alt="about" />
                        </div>
                    </div>
                    <div className="col-lg-7">
                        <div className="about-content content-right">
                        <span className="title-highlighter highlighter-primary2"> <i className="far fa-shopping-basket"></i>About Us</span>
                            <h4 className="title">Our story </h4>
                            <span className="text-heading">The SHPERO Health LLP was founded in 2017 with a vision to build the most sustainable system in the area of Healthcare & Agriculture and to develop products from the multifarious xerophytes that focus more on <b>“Waste to Value”</b> and <b>“Sustainable Development”.</b></span>
                            <p>
                            Prickly pear is a type of cactus that is commonly found in arid and semi-arid regions of the Americas, including Mexico, the southwestern United States, and parts of South America. It has been used for various medicinal and nutritional purposes for centuries by indigenous peoples of these regions.
                            </p>
                            {/* <Link to ="/Aboutas" className="axil-btn btn-outline">Get In Touch</Link> */}
                        </div>
                    </div>
                </div>
                <div className="row align-items-center">
                    <div className="col-lg-5 order-lg-2">
                        <div className="about-thumbnail">
                            <img src="images/about-03.png" alt="about" />
                        </div>
                    </div>
                    <div className="col-lg-7 order-lg-1">
                        <div className="about-content content-left">
                            {/* <span className="subtitle">Features #01</span> */}
                            <h4 className="title">How findla acts? </h4>
                            <span className="text-heading">Prickly pear cactus has several bioactive compounds that may contribute to its potential health benefits. Here are a few ways prickly pear may act in the body:</span>
                            <ul>
                                <li>Antioxidant activity</li>
                                <li>Anti-inflammatory activity</li>
                                <li>Blood sugar regulation</li>
                                <li>Cholesterol management</li>
                                <li>Hydration</li>
                                <li>symbols</li>
                              
                            </ul>
                                <Link to ="/contact" className="axil-btn btn-outline">Get In Touch</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- End About Area  --> */}
    </main>
    <AxilNewsletter />
    <ServiceArea />
    <Footer />
   </>
  )
}
