import React from "react";
import Footer from "./Footer";
import Header from "./Header";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useCart } from "react-use-cart";
import ServiceArea from "./ServiceArea";
import { useNavigate } from "react-router-dom";

const Carts = ({ cart, setCart, handleChange }) => {
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [couponcode, setCouponcode] = useState("");
  const [couponDiscount, setCouponDiscount] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const {
    isEmpty,
    totalUniqueItems,
    items,
    cartTotal,
    updateItemQuantity,
    removeItem,
  } = useCart();

  const [price, setPrice] = useState(0);

  const handleRemove = (id) => {
    const arr = cart.filter((item) => item.id !== id);
    setCart(arr);
  };

  const handlePrice = () => {
    let ans = 0;
  };

  useEffect(() => {
    handlePrice();
  });

  let processtochechout = async (e) => {
    const userDetail = JSON.parse(localStorage.getItem("user"));
    if (userDetail) {
      navigate("/CheckOut");
    } else {
      navigate("/SignIn");
    }
  };

  if (isEmpty)
    return (
      <p>
        {" "}
        <Header></Header>
        <div className="container">
          <div class="product-table-heading"></div>
          <div class="table-responsive"></div>
          <h2 className="mid">No product found</h2>
          <Link to="/Product">
            <button className="verify-btn axil-btn btn-bg-secondary productt">
              Product
            </button>
          </Link>
        </div>
      </p>
    );

  // const url1 = "http://jnkitsolutions.com/findla-backend/api.php?service=cart_item_list&uid=";
  // useEffect(() => {
  //   const userDetail = JSON.parse(localStorage.getItem('user'));
  //   // console.log(userDetail);

  //   fetch(url1 + userDetail.id )
  //     .then((response) => response.json())
  //     .then((json) => {

  //        setData(json.cart_history[0].uid);
  //     //    console.log(json.cart_history);
  //     //    console.log(json.cart_history[0].uid);
  //        setName(json.name)
  //       setLoading(false);
  //     })
  //     .catch((e) => {
  //       setLoading(false);
  //     });
  // }, []);

  return (
    <>
      <Header />

      <main className="main-wrapper">
        <div className="axil-product-cart-area axil-section-gap">
          <div className="container">
            <div className="axil-product-cart-wrap">
              <div className="product-table-heading">
                <h4 className="title">Your Cart</h4>
              </div>
              <div className="table-responsive">
                <table className="table axil-product-table axil-cart-table mb--40">
                  <thead>
                    <tr>
                      <th scope="col" className="product-remove"></th>
                      <th scope="col" className="product-thumbnail">
                        Product
                      </th>
                      <th scope="col" className="product-title"></th>
                      <th scope="col" className="product-price">
                        Price
                      </th>
                      <th scope="col" className="product-quantity">
                        Quantity
                      </th>
                      <th scope="col" className="product-subtotal">
                        Subtotal
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {items.map((cart) => (
                      <tr>
                        <td className="product-remove">
                          <button
                            onClick={() => removeItem(cart.id)}
                            className="remove-wishlist"
                          >
                            <i className="fal fa-times"></i>
                          </button>
                        </td>
                        <td className="product-thumbnail">
                          <Link to="#">
                            <img src={cart.image_url} alt={cart.name} />
                          </Link>
                        </td>
                        <td className="product-title">
                          <Link to="#">{cart.name}</Link>
                        </td>
                        <td className="product-price" data-title="Price">
                          <span className="currency-symbol">INR. </span>
                          {cart.price}
                        </td>
                        <td className="product-quantity" data-title="Qty">
                          <div className="pro-qty">
                            <span
                              class="dtc qtybtn"
                              onClick={() =>
                                updateItemQuantity(cart.id, cart.quantity - 1)
                              }
                            >
                              -
                            </span>
                            <input
                              type="number"
                              className="quantity-input"
                              value={cart.quantity}
                            />
                            <span
                              class="inc qtybtn"
                              onClick={() =>
                                updateItemQuantity(cart.id, cart.quantity + 1)
                              }
                            >
                              +
                            </span>
                          </div>
                        </td>
                        <td className="product-subtotal" data-title="Subtotal">
                          <span className="currency-symbol">INR. </span>
                          {cart.price}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="cart-update-btn-area"></div>
              <div className="row">
                <div className="col-xl-5 col-lg-7 offset-xl-7 offset-lg-5">
                  <div className="axil-order-summery mt--80">
                    <h5 className="title mb--20">Order Summary</h5>
                    <div className="summery-table-wrap">
                      <table className="table summery-table mb--30">
                        <tbody>
                          <tr className="order-subtotal">
                            <td>Subtotal</td>
                            <td>INR. {cartTotal}</td>
                          </tr>
                          <tr className="order-tax">
                            <td>State Tax</td>
                            <td>INR. 0.00</td>
                          </tr>
                          {couponDiscount > 0 && (
                            <tr className="order-tax">
                              <td>Discount</td>
                              <td> INR.-{couponDiscount}</td>
                            </tr>
                          )}
                          <tr className="order-total">
                            <td>Total</td>
                            <td className="order-total-amount">
                              INR. {cartTotal - couponDiscount}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <button
                      onClick={() => {
                        processtochechout();
                      }}
                      className="axil-btn btn-bg-primary checkout-btn"
                    >
                      Process to Checkout
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <ServiceArea />

      <Footer />

      <div
        className="modal fade quick-view-product"
        id="quick-view-modal"
        tabindex="-1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="far fa-times"></i>
              </button>
            </div>
            <div className="modal-body">
              <div className="single-product-thumb">
                <div className="row">
                  <div className="col-lg-7 mb--40">
                    <div className="row">
                      <div className="col-lg-10 order-lg-2">
                        <div className="single-product-thumbnail product-large-thumbnail axil-product thumbnail-badge zoom-gallery">
                          <div className="thumbnail">
                            <img
                              src="image/product-big-01.png"
                              alt="Product image"
                            />
                            <div className="label-block label-right">
                              <div className="product-badget">20% OFF</div>
                            </div>
                            <div className="product-quick-view position-view">
                              <Link
                                to="assets/image/product/product-big-01.png"
                                className="popup-zoom"
                              >
                                <i className="far fa-search-plus"></i>
                              </Link>
                            </div>
                          </div>
                          <div className="thumbnail">
                            <img
                              src="image/product-big-02.png"
                              alt="Product image"
                            />
                            <div className="label-block label-right">
                              <div className="product-badget">20% OFF</div>
                            </div>
                            <div className="product-quick-view position-view">
                              <Link
                                to="assets/image/product/product-big-02.png"
                                className="popup-zoom"
                              >
                                <i className="far fa-search-plus"></i>
                              </Link>
                            </div>
                          </div>
                          <div className="thumbnail">
                            <img
                              src="image/product-big-03.png"
                              alt="Product image"
                            />
                            <div className="label-block label-right">
                              <div className="product-badget">20% OFF</div>
                            </div>
                            <div className="product-quick-view position-view">
                              <Link
                                to="assets/image/product/product-big-03.png"
                                className="popup-zoom"
                              >
                                <i className="far fa-search-plus"></i>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-2 order-lg-1">
                        <div className="product-small-thumb small-thumb-wrapper">
                          <div className="small-thumb-img">
                            <img src="image/thumb-08.png" alt="thumb image" />
                          </div>
                          <div className="small-thumb-img">
                            <img src="image/thumb-07.png" alt="thumb image" />
                          </div>
                          <div className="small-thumb-img">
                            <img src="image/thumb-09.png" alt="thumb image" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5 mb--40">
                    <div className="single-product-content">
                      <div className="inner">
                        <div className="product-rating">
                          <div className="star-rating">
                            <img src="image/rate.png" alt="Rate image" />
                          </div>
                          <div className="review-link">
                            <Link to="#">
                              (<span>1</span> customer reviews)
                            </Link>
                          </div>
                        </div>
                        <h3 className="product-title">Serif Coffee Table</h3>
                        <span className="price-amount">$155.00 - $255.00</span>
                        <ul className="product-meta">
                          <li>
                            <i className="fal fa-check"></i>In stock
                          </li>
                          <li>
                            <i className="fal fa-check"></i>Free delivery
                            available
                          </li>
                          <li>
                            <i className="fal fa-check"></i>Sales 30% Off Use
                            Code: MOTIVE30
                          </li>
                        </ul>
                        <p className="description">
                          In ornare lorem ut est dapibus, ut tincidunt nisi
                          pretium. Integer ante est, elementum eget magna.
                          Pellentesque sagittis dictum libero, eu dignissim
                          tellus.
                        </p>

                        <div className="product-variations-wrapper">
                          <div className="product-variation">
                            <h6 className="title">Colors:</h6>
                            <div className="color-variant-wrapper">
                              <ul className="color-variant mt--0">
                                <li className="color-extra-01 active">
                                  <span>
                                    <span className="color"></span>
                                  </span>
                                </li>
                                <li className="color-extra-02">
                                  <span>
                                    <span className="color"></span>
                                  </span>
                                </li>
                                <li className="color-extra-03">
                                  <span>
                                    <span className="color"></span>
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>

                          <div className="product-variation">
                            <h6 className="title">Size:</h6>
                            <ul className="range-variant">
                              <li>xs</li>
                              <li>s</li>
                              <li>m</li>
                              <li>l</li>
                              <li>xl</li>
                            </ul>
                          </div>
                        </div>

                        <div className="product-action-wrapper d-flex-center">
                          <div className="pro-qty">
                            <input type="text" value="1" />
                          </div>

                          <ul className="product-action d-flex-center mb--0">
                            <li className="add-to-cart">
                              <Link
                                to="cart.html"
                                className="axil-btn btn-bg-primary"
                              >
                                Add to Cart
                              </Link>
                            </li>
                            <li className="wishlist">
                              <Link
                                to="wishlist.html"
                                className="axil-btn wishlist-btn"
                              >
                                <i className="far fa-heart"></i>
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="header-search-modal" id="header-search-modal">
        <button className="card-close sidebar-close">
          <i className="fas fa-times"></i>
        </button>
        <div className="header-search-wrap">
          <div className="card-header">
            <form action="#">
              <div className="input-group">
                <input
                  type="search"
                  className="form-control"
                  name="prod-search"
                  id="prod-search"
                  placeholder="Write Something...."
                />
                <button type="submit" className="axil-btn btn-bg-primary">
                  <i className="far fa-search"></i>
                </button>
              </div>
            </form>
          </div>
          <div className="card-body">
            <div className="search-result-header">
              <h6 className="title">24 Result Found</h6>
              <Link to="shop.html" className="view-all">
                View All
              </Link>
            </div>
            <div className="psearch-results">
              <div className="axil-product-list">
                <div className="thumbnail">
                  <Link to="single-product.html">
                    <img
                      src="image/product-09.png"
                      alt="Yantiti Leather Bags"
                    />
                  </Link>
                </div>
                <div className="product-content">
                  <div className="product-rating">
                    <span className="rating-icon">
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                      <i className="fal fa-star"></i>
                    </span>
                    <span className="rating-number">
                      <span>100+</span> Reviews
                    </span>
                  </div>
                  <h6 className="product-title">
                    <Link to="single-product.html">Media Remote</Link>
                  </h6>
                  <div className="product-price-variant">
                    <span className="price current-price">$29.99</span>
                    <span className="price old-price">$49.99</span>
                  </div>
                  <div className="product-cart">
                    <Link to="cart.html" className="cart-btn">
                      <i className="fal fa-shopping-cart"></i>
                    </Link>
                    <Link to="wishlist.html" className="cart-btn">
                      <i className="fal fa-heart"></i>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="axil-product-list">
                <div className="thumbnail">
                  <Link to="single-product.html">
                    <img
                      src="image/product-09.png"
                      alt="Yantiti Leather Bags"
                    />
                  </Link>
                </div>
                <div className="product-content">
                  <div className="product-rating">
                    <span className="rating-icon">
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                      <i className="fal fa-star"></i>
                    </span>
                    <span className="rating-number">
                      <span>100+</span> Reviews
                    </span>
                  </div>
                  <h6 className="product-title">
                    <Link to="single-product.html">Media Remote</Link>
                  </h6>
                  <div className="product-price-variant">
                    <span className="price current-price">$29.99</span>
                    <span className="price old-price">$49.99</span>
                  </div>
                  <div className="product-cart">
                    <Link to="cart.html" className="cart-btn">
                      <i className="fal fa-shopping-cart"></i>
                    </Link>
                    <Link to="wishlist.html" className="cart-btn">
                      <i className="fal fa-heart"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Carts;
