import React, { useState } from "react";
import axios from "axios";
import Header from "./Header";
import Footer from "./Footer";
import ServiceArea from "./ServiceArea";
import swal from "sweetalert";
import { Link } from "react-router-dom";

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setmobile] = useState("");
  const [message, setMessage] = useState("");

  let handleSubmit = async (e) => {
    e.preventDefault();
    try {
      var form = new FormData();
      form.append("service", "inquiry");
      form.append("name", name);
      form.append("email", email);
      form.append("mobile", mobile);
      form.append("message", message);

      await axios({
        method: "POST",
        url: "http://jnkitsolutions.com/findla-backend/api.php",
        data: form,
        headers: {
          "Content-Type": `multipart/form-data`,
        },
      }).then(function(response) {
        setMessage(response.ack_msg);
        setName("");
        setEmail("");
        setmobile("");
        setMessage("");
        swal("Thank you!", "Your Form is Submitted!", "success");
        // }).catch(function (response) {
        //     setMessage(response.ack_msg);
      });
    } catch (err) {
      setMessage(err);
    }
  };
  const checkInput = (e) =>{
    const onlyDigits = e.target.value.replace(/\D/g, "");
    setmobile(onlyDigits);
  }


  return (
    <>
      <Header />

      <main className="main-wrapper">
        <div className="axil-breadcrumb-area">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-8">
                <div className="inner">
                  <ul className="axil-breadcrumb">
                    <li className="axil-breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="separator"></li>
                    <li
                      className="axil-breadcrumb-item active"
                      aria-current="page"
                    >
                      Contact
                    </li>
                  </ul>
                  <h1 className="title">Contact With Us</h1>
                </div>
              </div>
              <div className="col-lg-6 col-md-4">
                <div className="inner">
                  <div className="bradcrumb-thumb">
                    <img src="images/product-45.png" alt="Image" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="axil-contact-page-area axil-section-gap">
          <div className="container">
            <div className="axil-contact-page">
              <div className="row row--30">
                <div className="col-lg-8">
                  <div className="contact-form">
                    <h3 className="title mb--10">
                      We would love to hear from you.
                    </h3>
                    <p>
                      If you’ve got great products your making or looking to
                      work with us then drop us a line.
                    </p>
                    <form
                      id="contact-form"
                      onSubmit={handleSubmit}
                      method="POST"
                      className="axil-contact-form"
                    >
                      <div className="row row--10">
                        <div className="col-lg-4">
                          <div className="form-group">
                            <label htmlFor="contact-name">
                              Name <span>*</span>
                            </label>
                            <input
                              type="text"
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                              name="contact-name"
                              id="contact-name"
                              required
                            />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <label htmlFor="contact-phone">
                              Phone <span>*</span>
                            </label>
                            <input
                            type="tel"
                            maxLength= "10"
                            minLength= "10"
                            name="contact-phone"
                            id="contact-phone"
                            onChange={(e)=> checkInput(e)} 
                            required
                            />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <label htmlFor="contact-email">
                              E-mail <span>*</span>
                            </label>
                            <input
                              type="email"
                              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
                              title="please enter valid Email"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              name="contact-email"
                              id="contact-email"
                              required
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group">
                            <label htmlFor="contact-message">
                              Your Message <span>*</span>
                            </label>
                            <textarea
                              name="contact-message"
                              value={message}
                              onChange={(e) => setMessage(e.target.value)}
                              id="contact-message"
                              cols="1"
                              rows="2"
                              required
                            ></textarea>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group mb--0">
                            <button
                              name="submit"
                              type="submit"
                              id="submit"
                              className="axil-btn btn-bg-primary"
                            >
                              Send Message
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="contact-location mb--40">
                    <h4 className="title mb--20">Our Store</h4>
                    <span className="address mb--20">
                      8212 E. Glen Creek Street Orchard Park, NY 14127, United
                      States of America
                    </span>
                    <span className="phone">Phone: +123 456 7890</span>
                    <span className="email">Email: Hello@etrade.com</span>
                  </div>
                  <div className="contact-career mb--40">
                    <h4 className="title mb--20">Careers</h4>
                    <p>
                      Instead of buying six things, one that you really like.
                    </p>
                  </div>
                  <div className="opening-hour">
                    <h4 className="title mb--20">Opening Hours:</h4>
                    <p>
                      Monday to Saturday: 9am - 10pm
                      <br /> Sundays: 10am - 6pm
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="axil-google-map-wrap axil-section-gap pb--0">
              <div className="mapouter">
                <div className="gmap_canvas">
                  {/* <iFrame width="1080" height="500" id="gmap_canvas" src="https://maps.google.com/maps?q=melbourne&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=&amp;output=embed"></iFrame> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      <ServiceArea />

      <Footer />

      <div
        className="modal fade quick-view-product"
        id="quick-view-modal"
        tabIndex="-1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="far fa-times"></i>
              </button>
            </div>
            <div className="modal-body">
              <div className="single-product-thumb">
                <div className="row">
                  <div className="col-lg-7 mb--40">
                    <div className="row">
                      <div className="col-lg-10 order-lg-2">
                        <div className="single-product-thumbnail product-large-thumbnail axil-product thumbnail-badge zoom-gallery">
                          <div className="thumbnail">
                            <img
                              src="image/product-big-01.png"
                              alt="Product image"
                            />
                            <div className="label-block label-right">
                              <div className="product-badget">20% OFF</div>
                            </div>
                            <div className="product-quick-view position-view">
                              <a
                                href="assets/image/product/product-big-01.png"
                                className="popup-zoom"
                              >
                                <i className="far fa-search-plus"></i>
                              </a>
                            </div>
                          </div>
                          <div className="thumbnail">
                            <img
                              src="image/product-big-02.png"
                              alt="Product image"
                            />
                            <div className="label-block label-right">
                              <div className="product-badget">20% OFF</div>
                            </div>
                            <div className="product-quick-view position-view">
                              <a
                                href="assets/image/product/product-big-02.png"
                                className="popup-zoom"
                              >
                                <i className="far fa-search-plus"></i>
                              </a>
                            </div>
                          </div>
                          <div className="thumbnail">
                            <img
                              src="image/product-big-03.png"
                              alt="Product image"
                            />
                            <div className="label-block label-right">
                              <div className="product-badget">20% OFF</div>
                            </div>
                            <div className="product-quick-view position-view">
                              <a
                                href="assets/image/product/product-big-03.png"
                                className="popup-zoom"
                              >
                                <i className="far fa-search-plus"></i>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-2 order-lg-1">
                        <div className="product-small-thumb small-thumb-wrapper">
                          <div className="small-thumb-img">
                            <img src="image/thumb-08.png" alt="thumb image" />
                          </div>
                          <div className="small-thumb-img">
                            <img src="image/thumb-07.png" alt="thumb image" />
                          </div>
                          <div className="small-thumb-img">
                            <img src="image/thumb-09.png" alt="thumb image" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5 mb--40">
                    <div className="single-product-content">
                      <div className="inner">
                        <div className="product-rating">
                          <div className="star-rating">
                            <img src="image/rate.png" alt="Rate image" />
                          </div>
                          <div className="review-link">
                            <Link to="#">
                              (<span>1</span> customer reviews)
                            </Link>
                          </div>
                        </div>
                        <h3 className="product-title">Serif Coffee Table</h3>
                        <span className="price-amount">$155.00 - $255.00</span>
                        <ul className="product-meta">
                          <li>
                            <i className="fal fa-check"></i>In stock
                          </li>
                          <li>
                            <i className="fal fa-check"></i>Free delivery
                            available
                          </li>
                          <li>
                            <i className="fal fa-check"></i>Sales 30% Off Use
                            Code: MOTIVE30
                          </li>
                        </ul>
                        <p className="description">
                          In ornare lorem ut est dapibus, ut tincidunt nisi
                          pretium. Integer ante est, elementum eget magna.
                          Pellentesque sagittis dictum libero, eu dignissim
                          tellus.
                        </p>

                        <div className="product-variations-wrapper">
                          <div className="product-variation">
                            <h6 className="title">Colors:</h6>
                            <div className="color-variant-wrapper">
                              <ul className="color-variant mt--0">
                                <li className="color-extra-01 active">
                                  <span>
                                    <span className="color"></span>
                                  </span>
                                </li>
                                <li className="color-extra-02">
                                  <span>
                                    <span className="color"></span>
                                  </span>
                                </li>
                                <li className="color-extra-03">
                                  <span>
                                    <span className="color"></span>
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>

                          <div className="product-variation">
                            <h6 className="title">Size:</h6>
                            <ul className="range-variant">
                              <li>xs</li>
                              <li>s</li>
                              <li>m</li>
                              <li>l</li>
                              <li>xl</li>
                            </ul>
                          </div>
                        </div>

                        <div className="product-action-wrapper d-flex-center">
                          <div className="pro-qty">
                            <input type="text" defaultValue="1" />
                          </div>

                          <ul className="product-action d-flex-center mb--0">
                            <li className="add-to-cart">
                              <a
                                href="cart.html"
                                className="axil-btn btn-bg-primary"
                              >
                                Add to Cart
                              </a>
                            </li>
                            <li className="wishlist">
                              <a
                                href="wishlist.html"
                                className="axil-btn wishlist-btn"
                              >
                                <i className="far fa-heart"></i>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="header-search-modal" id="header-search-modal">
        <button className="card-close sidebar-close">
          <i className="fas fa-times"></i>
        </button>
        <div className="header-search-wrap">
          <div className="card-header">
            <form action="#">
              <div className="input-group">
                <input
                  type="search"
                  className="form-control"
                  name="prod-search"
                  id="prod-search"
                  placeholder="Write Something...."
                />
                <button type="submit" className="axil-btn btn-bg-primary">
                  <i className="far fa-search"></i>
                </button>
              </div>
            </form>
          </div>
          <div className="card-body">
            <div className="search-result-header">
              <h6 className="title">24 Result Found</h6>
              <a href="shop.html" className="view-all">
                View All
              </a>
            </div>
            <div className="psearch-results">
              <div className="axil-product-list">
                <div className="thumbnail">
                  <a href="single-product.html">
                    <img
                      src="image/product-09.png"
                      alt="Yantiti Leather Bags"
                    />
                  </a>
                </div>
                <div className="product-content">
                  <div className="product-rating">
                    <span className="rating-icon">
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                      <i className="fal fa-star"></i>
                    </span>
                    <span className="rating-number">
                      <span>100+</span> Reviews
                    </span>
                  </div>
                  <h6 className="product-title">
                    <a href="single-product.html">Media Remote</a>
                  </h6>
                  <div className="product-price-variant">
                    <span className="price current-price">$29.99</span>
                    <span className="price old-price">$49.99</span>
                  </div>
                  <div className="product-cart">
                    <a href="cart.html" className="cart-btn">
                      <i className="fal fa-shopping-cart"></i>
                    </a>
                    <a href="wishlist.html" className="cart-btn">
                      <i className="fal fa-heart"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="axil-product-list">
                <div className="thumbnail">
                  <a href="single-product.html">
                    <img
                      src="image/product-09.png"
                      alt="Yantiti Leather Bags"
                    />
                  </a>
                </div>
                <div className="product-content">
                  <div className="product-rating">
                    <span className="rating-icon">
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                      <i className="fal fa-star"></i>
                    </span>
                    <span className="rating-number">
                      <span>100+</span> Reviews
                    </span>
                  </div>
                  <h6 className="product-title">
                    <a href="single-product.html">Media Remote</a>
                  </h6>
                  <div className="product-price-variant">
                    <span className="price current-price">$29.99</span>
                    <span className="price old-price">$49.99</span>
                  </div>
                  <div className="product-cart">
                    <a href="cart.html" className="cart-btn">
                      <i className="fal fa-shopping-cart"></i>
                    </a>
                    <a href="wishlist.html" className="cart-btn">
                      <i className="fal fa-heart"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="cart-dropdown" id="cart-dropdown">
        <div className="cart-content-wrap">
          <div className="cart-header">
            <h2 className="header-title">Cart review</h2>
            <button className="cart-close sidebar-close">
              <i className="fas fa-times"></i>
            </button>
          </div>
          <div className="cart-body">
            <ul className="cart-item-list">
              <li className="cart-item">
                <div className="item-img">
                  <a href="single-product.html">
                    <img src="image/product-01.png" alt="Commodo Blown Lamp" />
                  </a>
                  <button className="close-btn">
                    <i className="fas fa-times"></i>
                  </button>
                </div>
                <div className="item-content">
                  <div className="product-rating">
                    <span className="icon">
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                    </span>
                    <span className="rating-number">(64)</span>
                  </div>
                  <h3 className="item-title">
                    <a href="single-product-3.html">Wireless PS Handler</a>
                  </h3>
                  <div className="item-price">
                    <span className="currency-symbol">$</span>155.00
                  </div>
                  <div className="pro-qty item-quantity">
                    <input
                      type="number"
                      className="quantity-input"
                      defaultValue="15"
                    />
                  </div>
                </div>
              </li>
              <li className="cart-item">
                <div className="item-img">
                  <a href="single-product-2.html">
                    <img src="image/product-02.png" alt="Commodo Blown Lamp" />
                  </a>
                  <button className="close-btn">
                    <i className="fas fa-times"></i>
                  </button>
                </div>
                <div className="item-content">
                  <div className="product-rating">
                    <span className="icon">
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                    </span>
                    <span className="rating-number">(4)</span>
                  </div>
                  <h3 className="item-title">
                    <a href="single-product-2.html">Gradient Light Keyboard</a>
                  </h3>
                  <div className="item-price">
                    <span className="currency-symbol">$</span>255.00
                  </div>
                  <div className="pro-qty item-quantity">
                    <input
                      type="number"
                      className="quantity-input"
                      defaultValue="5"
                    />
                  </div>
                </div>
              </li>
              <li className="cart-item">
                <div className="item-img">
                  <a href="single-product-3.html">
                    <img src="image/product-03.png" alt="Commodo Blown Lamp" />
                  </a>
                  <button className="close-btn">
                    <i className="fas fa-times"></i>
                  </button>
                </div>
                <div className="item-content">
                  <div className="product-rating">
                    <span className="icon">
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                    </span>
                    <span className="rating-number">(6)</span>
                  </div>
                  <h3 className="item-title">
                    <a href="single-product.html">HD CC Camera</a>
                  </h3>
                  <div className="item-price">
                    <span className="currency-symbol">$</span>200.00
                  </div>
                  <div className="pro-qty item-quantity">
                    <input
                      type="number"
                      className="quantity-input"
                      defaultValue="100"
                    />
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div className="cart-footer">
            <h3 className="cart-subtotal">
              <span className="subtotal-title">Subtotal:</span>
              <span className="subtotal-amount">$610.00</span>
            </h3>
            <div className="group-btn">
              <a
                href="cart.html"
                className="axil-btn btn-bg-primary viewcart-btn"
              >
                View Cart
              </a>
              <a
                href="checkout.html"
                className="axil-btn btn-bg-secondary checkout-btn"
              >
                Checkout
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
