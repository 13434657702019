import React from "react";
import AxilNewsletter from "./AxilNewsletter";
import { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
export default function MainWrapper() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [data, setData] = useState([]);
  const [product, setProduct] = useState([]);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState([]);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [old_password, setOldPassword] = useState("");
  const [new_password, setNewPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [order_id, setOrderId] = useState("");
  const navigate = useNavigate();
  const [disable, setDisable] = useState(false);
  const [detail, setDetail] = useState([]);
  const [Orderhistory, setOrderhistory] = useState([]);
  const [address1, setAddress1] = useState([]);
  const [pincode, setPincode] = useState([]);

  const url =
    "http://jnkitsolutions.com/findla-backend/api.php?service=order_history&user_id=";
  useEffect(() => {
    setLoading(true);
    const userDetail = JSON.parse(localStorage.getItem("user"));
    // console.log(userDetail);
    fetch(url + userDetail.id)
      .then((response) => response.json())
      .then((json) => {
        setData(json.order_history);
        setOrderId(json.order_history[0].id);
        setLoading(false);

      })
      .catch((e) => {
        setLoading(false);
        setData([]);
      });
  }, []);
 
  
  useEffect(() => {
    const url1 = "http://jnkitsolutions.com/findla-backend/api.php?service=order_details&id=";
    const urllink = url1 + order_id;
    console.log(urllink);
    fetch(urllink)
    .then((response) => response.json())
    .then((json) => {

      setDetail(json.order_history.cart_items);

      setLoading(false);
    })
    .catch((e) => {
      setLoading(false);
    });
    setLoading(false);
  });
 
  const changeOrderHistory = (e) => {
    setOrderId(e);
  };

  useEffect(() => {
    const userDetail = JSON.parse(localStorage.getItem("user"));
    if (userDetail) {
      setName(userDetail.name);
      setEmail(userDetail.email);
      setPhone(userDetail.phone);
      setOldPassword(userDetail.old_password);
      setNewPassword(userDetail.new_password);
      setAddress1(userDetail.address1);
      setPincode(userDetail.pincode);
      setUser(userDetail);
    }
    if (!userDetail) {
      setUser("");
    }
  }, []);

  let handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const user = JSON.parse(localStorage.getItem("user"));
      var form = new FormData();
      form.append("service", "user_profile_update");
      form.append("id", user.id);
      form.append("name", name);
      form.append("email", email);
      form.append("phone", phone);
      await axios({
        method: "POST",
        url: "http://jnkitsolutions.com/findla-backend/api.php",
        data: form,
        headers: {
          "Content-Type": `multipart/form-data`,
        },
      })
        .then(function(response) {
          setMessage(response.ack_msg);
          if (response.data.ack === 1) {
            swal("Thank you!", response.data.ack_msg, "success");
            localStorage.setItem("user", JSON.stringify(response.data.user));
          } else {
            swal(response.data.ack_msg);
          }
        })
        .catch(function(response) {
          setMessage(response.ack_msg);
        });
    } catch (err) {
      setMessage(err);
    }
  };

  let handleAddress = async (e) => {
    e.preventDefault();
    try {
      var form = new FormData();
      form.append("service", "user_address_update");
      form.append("id", user.id);
      form.append("address", address1);
      form.append("pincode", pincode);
      await axios({
        method: "POST",
        url: "http://jnkitsolutions.com/findla-backend/api.php",
        data: form,
        headers: {
          "Content-Type": `multipart/form-data`,
        },
      })
        .then(function(response) {
          setMessage(response.ack_msg);
          if (response.data.ack === 1) {
            swal("Thank you!", response.data.ack_msg, "success");
            localStorage.setItem("user", JSON.stringify(response.data.user));
          } else {
            swal(response.data.ack_msg);
          }
        })
        .catch(function(response) {
          setMessage(response.ack_msg);
        });
    } catch (err) {
      setMessage(err);
    }
  };

  let handlePassword = async (e) => {
    e.preventDefault();
    try {
      var form = new FormData();
      form.append("service", "change_password");
      form.append("email", user.email);

      form.append("old_password", old_password);
      form.append("new_password", new_password);
      await axios({
        method: "POST",
        url: "http://jnkitsolutions.com/findla-backend/api.php",
        data: form,
        headers: {
          "Content-Type": `multipart/form-data`,
        },
      })
        .then(function(response) {
          setMessage(response.ack_msg);
          if (response.data.ack === 1) {
            swal("Thank you!", response.data.ack_msg, "success");

            localStorage.setItem("user", JSON.stringify(response.data.user));
          } else {
            swal(response.data.ack_msg);
          }
        })
        .catch(function(response) {
          setMessage(response.ack_msg);
        });
    } catch (err) {
      setMessage(err);
    }
  };
  const checkInput = (e) => {
    const onlyDigits = e.target.value.replace(/\D/g, "");
    setPhone(onlyDigits);
    setPincode(onlyDigits);
  };

  return (
    <>
      <main className="main-wrapper">
        {/* <!-- Start Breadcrumb Area  --> */}
        <div className="axil-breadcrumb-area">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-8">
                <div className="inner">
                  <ul className="axil-breadcrumb">
                    <li className="axil-breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="separator"></li>
                    <li
                      className="axil-breadcrumb-item active"
                      aria-current="page"
                    >
                      My Account
                    </li>
                  </ul>
                  <h1 className="title">Explore All Products</h1>
                </div>
              </div>
              <div className="col-lg-6 col-md-4">
                <div className="inner">
                  <div className="bradcrumb-thumb">
                    <img src="images/product-45.png" alt="Image" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
       
        {/* <!-- End Breadcrumb Area  --> */}

        {/* <!-- Start My Account Area  --> */}
        <div className="axil-dashboard-area axil-section-gap">
          <div className="container">
            <div className="axil-dashboard-warp">
              <div className="axil-dashboard-author">
                <div className="media">
                  <div className="thumbnail"></div>
                  <div className="media-body">
                    {user && <h5 className="title mb-0"> {user.name} </h5>}
                    <span className="joining-date"></span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-3 col-md-4">
                  <aside className="axil-dashboard-aside">
                    <nav className="axil-dashboard-nav">
                      <div className="nav nav-tabs" role="tablist">
                        <a
                          class="nav-item nav-link"
                          data-bs-toggle="tab"
                          href="#nav-account"
                          role="tab"
                          aria-selected="false"
                        >
                          <i class="fas fa-user"></i>Account Details
                        </a>
                        <a
                          className="nav-item nav-link"
                          data-bs-toggle="tab"
                          href="#nav-orders"
                          role="tab"
                          aria-selected="false"
                        >
                          <i className="fas fa-shopping-basket"></i>Orders
                        </a>
                        <a
                          class="nav-item nav-link"
                          data-bs-toggle="tab"
                          href="#nav-address1"
                          role="tab"
                          aria-selected="false"
                        >
                          <i class="fas fa-key"></i>Change Password
                        </a>
                        <a
                          class="nav-item nav-link"
                          data-bs-toggle="tab"
                          href="#nav-password"
                          role="tab"
                          aria-selected="false"
                        >
                          <i class="fas fa-address-book"></i>Address
                        </a>
                      </div>
                    </nav>
                  </aside>
                </div>
                <div className="col-xl-9 col-md-8">
                  <div className="tab-content">
                    <div
                      className="tab-pane fade show active"
                      id="nav-dashboard"
                      role="tabpanel"
                    >
                      <div className="axil-dashboard-overview">
                        {user && (
                          <div className="welcome-text"> {user.name} </div>
                        )}
                        <p>
                          From your account dashboard you can view your recent
                          orders, manage your shipping and billing address , and
                          edit your password and account details.
                        </p>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="nav-orders"
                      role="tabpanel"
                    >
                      <div className="axil-dashboard-order">
                        <div className="table-responsive">
                          {data.length == "" && (
                            <h2 className="noorder"> No order found</h2>
                          )}
                          {data.length > 0 && (
                            <table className="table">
                              <thead>
                                <tr>
                                  <th class="col orderr">Order</th>
                                  <th class="col Date">Date</th>
                                  <th class="col Status">Status</th>
                                  <th class="col Total">Total</th>
                                  <th class="col Action">Actions</th>
                                </tr>
                              </thead>

                              <tbody>
                                {data.length > 0 &&
                                  data.map((order) => (
                                    <tr>
                                      <th scope="row fff">#{order.cart_id}</th>
                                      <td>{order.deliverydate}</td>
                                      <td>{order.status}</td>
                                      <td>{order.finaltotal}</td>

                                      <td>
                                        <button
                                          type="button"
                                          class="btn btn-primary"
                                          data-bs-toggle="modal"
                                          data-bs-target="#staticBackdrop"
                                          onClick={() => {
                                            changeOrderHistory(order.cart_id);
                                          }}
                                        >
                                          view
                                        </button>

                                        <div
                                          class="modal fade"
                                          id="staticBackdrop"
                                          data-bs-backdrop="static"
                                          data-bs-keyboard="false"
                                          tabindex="-1"
                                          aria-labelledby="staticBackdropLabel"
                                          aria-hidden="true"
                                        >
                                          <div class="modal-dialog">
                                            <div class="modal-content">
                                              <div class="modal-header">
                                                <h1
                                                  class="modal-title fs-5 orderr"
                                                  id="staticBackdropLabel orderr"
                                                >
                                                  order items
                                                </h1>
                                                <button
                                                  type="button"
                                                  class="btn-close"
                                                  data-bs-dismiss="modal"
                                                  aria-label="Close"
                                                ></button>
                                              </div>
                                              <div class="modal-body">
                                                <div className="axil-dashboard-order">
                                                  <div className="table-responsive">
                                                    <table className="table">
                                                      <thead>
                                                        <tr>
                                                          <th
                                                            scope="colll"
                                                            className="colld"
                                                          >
                                                            Product Name
                                                          </th>
                                                          <th
                                                            scope="col"
                                                            className="stt"
                                                          >
                                                            Qty
                                                          </th>
                                                          <th
                                                            scope="col"
                                                            className="serw"
                                                          >
                                                            Total
                                                          </th>
                                                        </tr>
                                                      </thead>
                                                    </table>
                                                  </div>
                                                </div>

                                                <tbody>
                                                  {detail.map((cart) => (
                                                    <tr>
                                                      <td className="name">
                                                        {" "}
                                                        {cart.name}
                                                      </td>
                                                      <td className="status">
                                                        {cart.qty}
                                                      </td>
                                                      <td className="fill">
                                                        {cart.subtotal}
                                                      </td>
                                                    </tr>
                                                  ))}
                                                </tbody>
                                              </div>
                                              <div class="modal-footer">
                                                <button
                                                  type="button"
                                                  class="btn btn-secondary"
                                                  data-bs-dismiss="modal"
                                                >
                                                  Close
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          )}
                        </div>
                      </div>
                    </div>

                    <div class="tab-pane fade" id="nav-account" role="tabpanel">
                      <div class="col-lg-9">
                        <div class="axil-dashboard-account">
                          <form
                            class="account-details-form"
                            onSubmit={handleSubmit}
                          >
                            <div class="row">
                              <div class="col-12">
                                <div class="form-group fdf">
                                  <label>First Name</label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    required
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                  />
                                </div>
                              </div>
                              <div class="col-12">
                                <div class="form-group fdf">
                                  <label>Email</label>
                                  <input
                                    type="email"
                                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
                                    title="please enter valid Email"
                                    class="form-control"
                                    required
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                  />
                                </div>
                              </div>

                              <div class="col-12">
                                <div class="form-group _1 fdf">
                                  <label>Phone</label>
                                  <input
                                    type="phone"
                                    minLength="10"
                                    maxLength="10"
                                    value={phone}
                                    // pattern="\d{10}"
                                    className="form-control"
                                    // onChange={(e) => setPhone(e.target.value)}
                                    onChange={(e) => checkInput(e)}
                                    // title="please enter correct phone number"
                                    name="contact-phone"
                                    id="contact-phone"
                                    // name="phone"
                                    placeholder="Please Enter Your phone number"
                                    required
                                  />
                                </div>
                              </div>
                              <div class="col-12">
                                <div class="form-group mb--0">
                                  <button
                                    type="submit"
                                    className="axil-btn btn-bg-primary submit-btn"
                                    disabled={disable}
                                    onClick=" window.location = '/Myaccount';"
                                  >
                                    submit
                                  </button>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                    <div
                      class="tab-pane fade"
                      id="nav-address1"
                      role="tabpanel"
                    >
                      <div class="col-lg-9">
                        <div class="axil-dashboard-account">
                          <form
                            class="account-details-form"
                            onSubmit={handlePassword}
                          >
                            <div class="row">
                              <div class="col-12">
                                <h5 class="title pass hhh">Password Change</h5>
                                <div class="form-group">
                                  <label> Old Password</label>
                                  <input
                                    type="password"
                                    class="form-control"
                                    value={old_password}
                                    onChange={(e) =>
                                      setOldPassword(e.target.value)
                                    }
                                    required
                                  />
                                </div>
                                <div class="form-group">
                                  <label>New Password</label>
                                  <input
                                    type="password"
                                    class="form-control"
                                    value={new_password}
                                    onChange={(e) =>
                                      setNewPassword(e.target.value)
                                    }
                                    required
                                  />
                                </div>

                                <div class="form-group mb--0">
                                  <button
                                    type="submit"
                                    className="axil-btn btn-bg-primary submit-btn"
                                    disabled={disable}
                                    onClick=" location.reload()"
                                  >
                                    submit
                                  </button>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                    <div
                      class="tab-pane fade"
                      id="nav-password"
                      role="tabpanel"
                    >
                      <div class="col-lg-9">
                        <div class="axil-dashboard-account">
                          <form
                            class="account-details-form"
                            onSubmit={handleAddress}
                          >
                            <div class="row">
                              <div class="col-12">
                                <h5 class="title pass hhh">Address</h5>

                                <div class="form-group">
                                  <label> Address</label>
                                  <textarea
                                    class="form-control"
                                    value={address1}
                                    onChange={(e) =>
                                      setAddress1(e.target.value)
                                    }
                                    required
                                  />
                                </div>

                                <div className="form-group">
                                  <label>Pincode</label>
                                  <input
                                    minLength="6"
                                    maxLength="6"
                                    // maxLength="6"
                                    // minLength="6"
                                    //  pattern="\d{6}"
                                    title="please enter correct pincode"
                                    onChange={(e) => checkInput(e)}
                                    name="pincode"
                                    placeholder="Enter the pincode "
                                    value={pincode}
                                    id="pincode"
                                    type="tel"
                                    class="pin"
                                    required
                                  />
                                </div>

                                <div class="form-group mb--0">
                                  <button
                                    type="submit"
                                    className="axil-btn btn-bg-primary submit-btn"
                                    disabled={disable}
                                    onClick=" location.reload()"
                                  >
                                    submit
                                  </button>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- End My Account Area  --> */}

        <AxilNewsletter />
      </main>
    </>
  );
}
