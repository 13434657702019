import React from 'react'
import Footer from './Footer'
import Header from './Header'
import MainWrapper from './MainWrapper'
import ServiceArea from './ServiceArea'
export default function Myaccount() {
  return (
    <>
    <body className="sticky-header">
    <a href="#top" className="back-to-top" id="backto-top"><i className="fal fa-arrow-up"></i></a>
    {/* <!-- Start Header --> */}
    <Header />

    <MainWrapper />

    <ServiceArea />

    <Footer />
    </body>
    </>
  )
}
