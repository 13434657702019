import React from "react";

export default function AxilNewsletter() {
  return (

    <>
      <div className="axil-newsletter-area axil-section-gap pt--0">
        <div className="container">
          <div className="etrade-newsletter-wrapper bg_image bg_image--5">
            <div className="newsletter-content">
              <span className="title-highlighter highlighter-primary2 neww">
                <i className="fas fa-envelope-open"></i>Newsletter
              </span>
              <h2 className="title mb--40 mb_sm--30">Get weekly update</h2>
            </div>
          </div>
        </div>
      </div>
    </>
    
  );
}
