import React from "react";
import { Link } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useCart } from "react-use-cart";
function SignIn(setCart) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [disable, setDisable] = useState(false);
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const[name,setName]=useState("");
  const { items,addItem ,cart_history } = useCart();
  const addNewItem = (item) => {
    const user = JSON.parse(localStorage.getItem('user'));
    addItem(item);
    // addItemServer(item);
   
  };

  let handleSubmit = async (e) => {
    e.preventDefault();
    try {
      var form = new FormData();
      form.append("service", "login");
      form.append("email", email);
      form.append("password", password);

      await axios({
        method: "POST",
        url: "http://jnkitsolutions.com/findla-backend/api.php",
        data: form,
        headers: {
          "Content-Type": `multipart/form-data`,
        },
      })
        .then(function(response) {
          setMessage(response.ack_msg);

          if (response.data.ack === 1) {
            localStorage.setItem("user", JSON.stringify(response.data.user));
            var orderItems = [];
            const doubled = items.map((item) => 
            orderItems.push({
            product_id: item.id,
            product_name: item.name,
            qty: Number(item.quantity),
            subtotal: Number(item.price),
            ship_charge: "",
            ship_days: "",
            total: item.price * item.quantity,
        })
      );

    
    // addItem(item);
            
           
            // console.log(cart);

            const url1 = "http://jnkitsolutions.com/findla-backend/api.php?service=cart_item_list&uid=";
            fetch(url1 + response.data.user.id )
            .then((response) => response.json())
            .then((json) => {
               json.cart_history.map((item) => 
                addNewItem (item)
               ); 
              //  console.log(item);
            }) 
            .catch((e) => {
             
            });
            navigate("/");
          } else {
            swal(response.data.ack_msg);
          }
        })
        .catch(function(response) {
          setMessage(response.ack_msg);
        });
    } catch (err) {
      setMessage(err);
    }
  };
  const user = sessionStorage.getItem("user");
  if (user) return JSON.parse(user);

  const handleLogout = () => {
   
    sessionStorage.removeItem("user");
    sessionStorage.removeItem("user");
  };
  

  return (
    <div className="axil-signin-area">
      <div className="signin-header">
        <div className="row align-items-center">
          <div className="col-sm-4">
            <Link to="/" className="site-logo">
              <img src="images/logo.png" alt="logo" />
            </Link>
          </div>
          <div className="col-sm-8">
            <div className="singin-header-btn">
              <p>Not a member?</p>
              <Link
                to="/SignUp"
                className="axil-btn btn-bg-secondary sign-up-btn"
              >
                Sign Up Now
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-4 col-lg-6">
          <div className="axil-signin-banner bg_image bg_image--9">
            <h3 className="title">We Offer the Best Products</h3>
          </div>
        </div>
        <div className="col-lg-6 offset-xl-2">
          <div className="axil-signin-form-wrap">
            <div className="axil-signin-form">
              <h3 className="title">Sign in to Findla.</h3>
              <p className="b2 mb--55">Enter your detail below</p>
              <form className="singin-form" onSubmit={handleSubmit}>
                <div className="form-group">
                  <label>Email <span>*</span></label>
                  <input
                    type="email"
                    className="form-control"
                    value={email}
                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" title="please enter valid Email"
                    onChange={(e) => setEmail(e.target.value)}
                    name="email"
                    placeholder="Ex: Example123@gmail.com"
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Password <span>*</span></label>
                  <input
                    type="password"
                    className="form-control"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    name="password"
                    placeholder="Please Enter Your Password"
                    required
                  />
                </div>
                <div className="form-group d-flex align-items-center justify-content-between">
                  <button
                    type="submit"
                    className="axil-btn btn-bg-primary submit-btn"
                  >
                    Sign In
                  </button>
                  <Link to="/Forgot" className="forgot-btn">
                    Forget password?
                  </Link>
                </div>
                <div className="message">
                  {message ? <p>{message}</p> : null}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default SignIn;
