import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Slider from 'react-slick';
import { useCart } from 'react-use-cart';

const Header1 = () => {

    const {totalItems} = useCart();
    const[user,setUser]=useState([]);
    const navigate=useNavigate();

    useEffect(() => {
        const userDetail = JSON.parse(localStorage.getItem('user'));
        if (userDetail) {
            setUser(userDetail);
        }
        if(!userDetail){
            setUser('')
        }
    }, []);

    const logout = () => {
        const userDetail = JSON.parse(localStorage.getItem('user'));
        window.location.reload()
        localStorage.removeItem('user');
        navigate("/")
        localStorage.clear( );
        // sessionStorage.clear( );
    }

const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  const processtochechout=()=>{
    const userDetail = JSON.parse(localStorage.getItem('user'));
    const navigate=useNavigate();   
    if (userDetail) {
        navigate("/CheckOut")
    }else{
        navigate("/SignIn")
    }
}

    
  return (
    <>

    <header className="header axil-header header-style-5">
    <div id="axil-sticky-placeholder"></div>

        <div className="container">
            <div className="header-navbar">
            <div className="header-brand">
                <Link to="/" className="logo logo-dark">
                
                </Link>
                <Link to="/" className="logo logo-light">
                
                </Link>
            </div>
            <div className="header-main-nav">
                {/* <!-- Start Mainmanu Nav --> */}
                <nav className="mainmenu-nav">
                    <button className="mobile-close-btn mobile-nav-toggler"><i className="fas fa-times"></i></button>
                    <div className="mobile-nav-brand">
                        <Link to="/" className="logo">
                        <img src="/images/logo.png" alt="Site Logo" />
                        </Link>
                    </div>
                    
                </nav>
            </div>
            <div className="header-action">
                <ul className="action-list">
                    <li className="axil-search d-xl-none d-block">
                        
                    </li>
                    {user &&
                        <Link to="/Myaccount">
                            <button className="verify-btn axil-btn btn-bg-secondary logg" > {user.name}</button>
                        </Link>
                    }
                    
                    {user == undefined || user == '' &&
                        <Link to="/Signin">
                            <button className="verify-btn axil-btn btn-bg-secondary logg lo" >Sign in</button>
                        </Link>
                    }
                    
                    {user &&
                        <Link to="/SignIn">
                        <button className="verify-btn axil-btn btn-bg-secondary logg lo" onClick={() => logout()} >Logout</button>
                    </Link>
                    } 
                    <li class="shopping-cart fg">
                        <Link to ="/cards" class="cart-dropdown-btn">
                        { totalItems > 0 &&
                            <span class="cart-count">{totalItems}</span>
                        }
                           <Link to="/Cards"  >
                            <i class="flaticon-shopping-cart ffff"></i>
                            </Link>
                        </Link>
                    </li>

                    {/*<li className="axil-mobile-toggle">
                        <button className="menu-btn mobile-nav-toggler">
                        <i className="flaticon-menu-2"></i>
                        </button>
                    </li>*/}
                </ul>
            </div>
            </div>
        </div>
    
    
    </header>
    
    
    </>
  )
}

export default Header1