import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useCart } from "react-use-cart";
import { NavLink } from "react-router-dom";

export default function Header() {
  const { totalItems } = useCart();

  const [user, setUser] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    const userDetail = JSON.parse(localStorage.getItem("user"));

    if (userDetail) {
      setUser(userDetail);
    }
    if (!userDetail) {
      setUser("");
    }
  }, []);

  const logout = () => {
    const userDetail = JSON.parse(localStorage.getItem("user"));
    window.location.reload();
    localStorage.removeItem("user");
    navigate("/");
    localStorage.clear();
    // sessionStorage.clear( );
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  //   const processtochechout = () => {
  //     const userDetail = JSON.parse(localStorage.getItem("user"));
  //     const navigate = useNavigate();
  //     if (userDetail) {
  //       navigate("/Product");
  //     } else {
  //       navigate("/SignIn");
  //     }
  //   };

  const [data, setData] = useState([]);
  const renderHTML = (rawHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML },
    });
  const url = "http://jnkitsolutions.com/findla-backend/api.php?service=offer_list";
  useEffect(() => {
    fetch(url)
      .then((response) => response.json())
      .then((json) => {
        setData(json.offers);
      })
      .catch((e) => {});
  }, []);

  return (
    <>
      <header className="header axil-header header-style-5">
        <div id="axil-sticky-placeholder"></div>
        <div className="axil-mainmenu">
          <div className="container">
            <div className="header-navbar">
              <div className="header-brand">
                <Link to="/" className="logo logo-dark">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/logo.png `}
                    alt="Site Logo"
                  />
                </Link>
                <Link to="/" className="logo logo-light">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/logo-light.png`}
                  />
                </Link>
              </div>
              <div className="header-main-nav">
                {/* <!-- Start Mainmanu Nav --> */}
                <nav className="mainmenu-nav">
                  <button className="mobile-close-btn mobile-nav-toggler">
                    <i className="fas fa-times"></i>
                  </button>
                  <div className="mobile-nav-brand">
                    <Link to="/" className="logo">
                      {/*<img src={`${process.env.PUBLIC_URL}/images/logo-light.png`}/>*/}
                      <img src={`${process.env.PUBLIC_URL}/images/logo.png`} />
                    </Link>
                  </div>
                  <ul className="mainmenu">
                    <li>
                      <Link ClassName="active" to="/">
                        Home
                      </Link>
                    </li>
                    <li>
                      <NavLink activeClassName="active" to="/Aboutas">
                        About
                      </NavLink>
                    </li>
                    <li>
                      <NavLink activeClassName="active" to="/Product">
                        Product
                      </NavLink>
                    </li>
                    <li>
                      <NavLink activeClassName="active" to="/Contact">
                        Contact
                      </NavLink>
                    </li>
                  </ul>
                </nav>
              </div>
              <div className="header-action">
                <ul className="action-list">
                  <li className="axil-search d-xl-none d-block"></li>
                  {user == undefined ||
                    (user == "" && (
                      <Link to="/SignUp">
                        <button className="verify-btn axil-btn btn-bg-secondary ff">
                          SignUp
                        </button>
                      </Link>
                    ))}
                  {user && (
                    <Link to="/Myaccount">
                      <button className="verify-btn axil-btn btn-bg-secondary ff">
                        {" "}
                        {user.name}
                      </button>
                    </Link>
                  )}
                  {user && (
                    <Link to="/">
                      <button
                        className="verify-btn axil-btn btn-bg-secondary ff lo"
                        onClick={() => logout()}
                      >
                        Logout
                      </button>
                    </Link>
                  )}
                  <li class="shopping-cart fg">
                    <Link to="/Cards" class="cart-dropdown-btn">
                      {totalItems > 0 && (
                        <span class="cart-count">{totalItems}</span>
                      )}
                      <Link to="/Cards">
                        <i class="flaticon-shopping-cart"></i>
                      </Link>
                    </Link>
                  </li>

                  <li className="axil-mobile-toggle">
                    <button className="menu-btn mobile-nav-toggler">
                      <i className="flaticon-menu-2"></i>
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
