import React  from "react";
import Header from "./Header";
import Footer from "./Footer";
import ServiceArea from "./ServiceArea";
import Accordion from "react-bootstrap/Accordion";
import { Form, Link, useNavigation } from "react-router-dom";
import { useState, useEffect } from "react";
import { useCart } from "react-use-cart";
import swal from 'sweetalert';
import {useNavigate} from 'react-router-dom';

import axios from 'axios';
import Pincode from "./Pincode";
import useRazorpay from "react-razorpay";
import { FormControl, NavLink } from "react-bootstrap";
      
 function CheckOut({ cart, setCart }) {
    const navigate = useNavigate();
    const { emptyCart } = useCart();
    const[name,setName]=useState("");
    const [message, setMessage] =  useState("");
    const [couponcode, setCouponcode] = useState("");
    const [couponDiscount, setCouponDiscount] = useState(0);
    const [mobilew, setmobilee] = useState("");
    const [price, setPrice] = useState(0);
    const [charge, setCharge] = useState("");
    const [casht, setCasht] = useState(0);
    const [phone ,setPhone  ] = useState();
    const [payment ,setPayment  ] = useState("");
    const [address1 ,setAddress1  ] = useState();
    const [city ,setCity   ] = useState();
    const [state , setState  ] = useState();
    const [pincode , setPincode  ] = useState();
    const [other_notes , setOther_notes   ] = useState();
    const [coupon_code , setCoupon_code   ] = useState();
    const [discount , setDiscount   ] = useState();
    const [payment_method ,setPayment_method  ] = useState();
    const [cod_charge , setCod_charge  ] = useState();
    const [finaltotal ,setFinaltotal ] = useState()
    const [show,setShow]=useState("")
    const [orderItems,setOrderItems]=useState("")
    const [delivery , setDelivery]=useState("")
    const {
        isEmpty,
        totalUniqueItems,
        items,
        cartTotal,
        updateItemQuantity,
        removeItem,
      } = useCart();

  const handleRemove = (id) => {
    const arr = cart.filter((item) => item.id !== id);
    setCart(arr);
  };
  
  const handlePrice = () => {
    let ans = 0;
  };
  const Razorpay = useRazorpay();

  const handlePayment = async (e) => {
    e.preventDefault();
    const userDetail = JSON.parse(localStorage.getItem('user'));
    
    const pay = cartTotal - couponDiscount + Number(charge.cod_charge)
    if(payment == 'online'){
          const options = {
              key: "rzp_test_Mp4pR8v28JlnC8", // Enter the Key ID generated from the Dashboard
              amount: pay * 100,  // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
              currency: "INR",
              name: userDetail.name,
              description: "Demo Payment For Findla",
              image: "https://new.axilthemes.com/demo/template/etrade/assets/images/logo/logo.png",
              handler: function (response) {
                  alert(response.razorpay_payment_id)
                  try {
                    var orderItems = [];
                    const doubled = items.map((item) => 
                      orderItems.push({
                          product_id: item.id,
                          product_name: item.name,
                          qty: Number(item.quantity),
                          subtotal: Number(item.price),
                          coupon_id: "",
                          discount: "",
                          ship_charge: "",
                          ship_days: "",
                          total: item.price * item.quantity,
                      })
                    );

                    const user = JSON.parse(localStorage.getItem('user'));
                    var form = new FormData();
                    form.append('service', 'order_placed');
                    form.append('uid', user.id);
                    form.append('phone', phone);
                    form.append('address1', address1); 
                    form.append('city', city);
                    form.append('state', city);
                    form.append('pincode', pincode);
                    form.append('subtotal', cartTotal);
                    form.append('coupon_id', "");
                    form.append('coupon_code', couponcode);
                    form.append('discount', couponDiscount);
                    form.append('payment_method', "1");
                    form.append('cod_charge', Number(charge.cod_charge));
                    form.append('finaltotal', casht);
                    form.append('other_notes', other_notes);
                    form.append('cart_items', JSON.stringify(orderItems));
                    form.append('transaction_id', response.razorpay_payment_id);
                    axios({
                        method: "POST",
                        url: "http://jnkitsolutions.com/findla-backend/api.php",
                        data: form,
                        headers: {
                            'Content-Type': `multipart/form-data`,
                        },
                        
                        
                    }).then(function (response) {
                        setMessage(response.ack_msg);
                        setPincode('');
                        // setEmail('');
                        setAddress1('');
                        setCity('');
                        setPhone('');
                        setOther_notes('');
                        emptyCart() ;
                        try {
                          const user = JSON.parse(localStorage.getItem('user'));
                          var form = new FormData();
                          form.append("service", "user_profile_update");
                          form.append("id", user.id);
                          form.append("name", user.name);
                          form.append("email", user.email);
                          form.append("phone", phone);
                          axios({
                            method: "POST",
                            url: "http://jnkitsolutions.com/findla-backend/api.php",
                            data: form,
                            headers: {
                              "Content-Type": `multipart/form-data`,
                            },
                          })
                            .then(function(response) {
                              if (response.data.ack === 1) {
                                localStorage.setItem("user",JSON.stringify(response.data.user))
                              }
                            })
                            .catch(function(response) {
                            });
                        } catch (err) {}
                        swal("Thank you!", "Your order is Submitted!", "success");
                        // window.location = '/Myaccount';
                        navigate("/Myaccount");
                        
                        // const goTOmyaccount = () =>{
                        //   navigate("")
                        // }
                        // }).catch(function (response) {
                        //     setMessage(response.ack_msg);
                    });
                } catch (err) {
                  setMessage(err);
                }
                      },
                      prefill: {
                      name: userDetail.name,
                      email: userDetail.email,
                      phone: userDetail.phone,
                      },
                      notes: {
                      address: "ahmedabad",
                      },
                      theme: {
                      color: "#3399cc",
                      },
                  }
          const rzp1 = new Razorpay(options);
          rzp1.on("payment.failed", function (response) {
              alert(response.error.code);   
          });
          rzp1.open();
    }else{
      try {
        var orderItems = [];
        const doubled = items.map((item) => 
          orderItems.push({
              product_id: item.id,
              product_name: item.name,
              qty: Number(item.quantity),
              subtotal: Number(item.price),
              coupon_id: "",
              discount: "",
              ship_charge: "",
              ship_days: "",
              total: item.price * item.quantity,
          })
        );

        const user = JSON.parse(localStorage.getItem('user'));
        var form = new FormData();
        form.append('service', 'order_placed');
        form.append('uid', user.id);
        form.append('phone', phone);
        form.append('address1', address1); 
        form.append('city', city);
        form.append('state', city);
        form.append('pincode', pincode);
        form.append('subtotal', cartTotal);
        form.append('coupon_id', "");
        form.append('coupon_code', couponcode);
        form.append('discount', couponDiscount);
        form.append('payment_method', "1");
        form.append('cod_charge', Number(charge.cod_charge));
        form.append('finaltotal', casht);
        form.append('other_notes', other_notes);
        form.append('cart_items', JSON.stringify(orderItems));

        axios({
            method: "POST",
            url: "http://jnkitsolutions.com/findla-backend/api.php",
            data: form,
            headers: {
                'Content-Type': `multipart/form-data`,
            },
        }).then(function (response) {
            setMessage(response.ack_msg);
            setPincode('');
            // setEmail('');
            setAddress1('');
            setCity('');
            setPhone('');
            setOther_notes('');
            emptyCart();
            try {
              const user = JSON.parse(localStorage.getItem('user'));
              var form = new FormData();
              form.append("service", "user_profile_update");
              form.append("id", user.id);
              form.append("name", user.name);
              form.append("email", user.email);
              form.append("phone", phone);
              axios({
                method: "POST",
                url: "http://jnkitsolutions.com/findla-backend/api.php",
                data: form,
                headers: {
                  "Content-Type": `multipart/form-data`,
                },
              })
                .then(function(response) {
                  if (response.data.ack === 1) {
                    localStorage.setItem("user",JSON.stringify(response.data.user))
                  }
                })
                .catch(function(response) {
                });
            } catch (err) {}
            swal("Thank you!", "Your order is Submitted!", "success");
            
            navigate("/Myaccount");
           

        });
      } catch (err) {
        setMessage(err);
      }
    }
    };
    const Home = () => {
      const navigate = useNavigate();
    }
  useEffect(() => {
    handlePrice();
    setCasht(cartTotal);
    if(charge){
      if(charge.cod_charge){
        setCasht(Number(cartTotal - couponDiscount) + Number(charge.cod_charge));
      }else{
        setCasht(Number(cartTotal - couponDiscount));
      }
    }else{
      setCasht(Number(cartTotal - couponDiscount));
    } 
  });

  const handleSubmit = async () => {
    try {
        var form = new FormData();
        form.append('service', 'coupon_find');
        form.append('coupon_code', couponcode); 

        await axios({
            method: "POST",
            url: "http://jnkitsolutions.com/findla-backend/api.php",
            data: form,
            headers: {
                'Content-Type': `multipart/form-data`,
            },
        }).then(function (response) {
            setMessage(response.ack_msg);
            setName('');
            if (response.data.ack === 1 ) {
                const couponObject = response.data.coupon;
                if(cartTotal > couponObject.min_amount){
                    let discount = 0;
                    if(couponObject.disc_type == '1'){
                        discount = cartTotal * Number(couponObject.discount) / 100;
                    }else{
                        discount = couponObject.discount;
                    }
                    setCouponDiscount(discount);
                    swal(response.data.ack_msg);
                }else{
                    swal('please purchase minimum '+ couponObject.min_amount + ' INR.');
                }
            } else {
                swal(response.data.ack_msg);
            }
        }).catch(function (response) {  
            setMessage(response.ack_msg);
        });     
    } catch (err) {
    //   setMessage(err);
    }
}

  const callbackFunction = (Data) => {
    if(Data){
      setCharge(Data);
      setPincode(Data.pincode);
      if(Data.cod_charge){
        setCasht(Number(cartTotal) + Number(Data.cod_charge));
      }else{
        setCasht(cartTotal);
      }
    }else{
      setCharge("");
      setCasht(cartTotal);
    }
  }
  
  const removeCoupon = () => {
    setCouponDiscount(0);
    if(charge){
      setCasht(cartTotal + Number(charge.cod_charge));
    }else{
      setCasht(cartTotal);
    }
  }
  
  const useMountEffect = fun => useEffect(fun, [])
  useMountEffect(() => {
    const userDetail = JSON.parse(localStorage.getItem('user'));
    setPhone(userDetail.phone);
    setAddress1(userDetail.address1)
    setPincode(userDetail.pincode)

  })

  const checkInput = (e) =>{
    const onlyDigits = e.target.value.replace(/\D/g, "");
    setPhone(onlyDigits);
  }



  return (
    <>
    <div className="sticky-header overflow-md-visible" ></div>
      <Header />
      <main className="main-wrapper">
        {/* <!-- Start Checkout Area  --> */}
        <div className="axil-checkout-area axil-section-gap">
          <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <div className="axil-checkout-notice">
                    <Accordion defaultActiveKey={["0"]}>
                      <div className="axil-toggle-box">
                        <Accordion.Item eventKey="1">
                         
                        </Accordion.Item>
                      </div>
                      { couponDiscount <= 0 &&
                        <div className="axil-toggle-box">
                          <Accordion.Item eventKey="2">
                            <Accordion.Header>
                              <div className="toggle-bar">
                                <i className="fas fa-pencil"></i> Have a coupon?{" "}
                                <Link to="#" className="toggle-btn">
                                  Click here to enter your code{" "}
                                </Link>
                              </div>
                            </Accordion.Header>
                            <Accordion.Body>
                              <div className="axil-checkout-coupon ">
                                <p>
                                  If you have a coupon code, please apply it
                                  below.
                                </p>

                                <div className="input-group">
                                <input placeholder="Enter coupon code" type="text" value={couponcode} onChange={(e)=>setCouponcode(e.target.value)}/>
                                
                                  <div className="apply-btn">
                                  <button type="submit" className="axil-btn btn-outline" onClick={() => handleSubmit()}>Apply</button>
                                  </div>
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        </div>
                      }
                    </Accordion>
                  </div>
                  </div>                  
              </div>             
          </div>

        </div>

        <div className="chekk">
        <form id="contact-form"  method="POST" className="axil-contact-form" onSubmit={handlePayment}>
        <div className="container">
        <div className="row">
        <div className="col-lg-6">
        
        <h4 className="title mb--40">Billing details</h4>
        <div className="row"></div>

        <div className="form-group">
        <Pincode parentCallback = {callbackFunction} data={pincode}/>
        </div>

        <div className="form-group">
          <label>
            Phone number <span>*</span>
          </label>
          <input
            type="tel"
            maxLength= "10"
            minLength= "10"
            value={phone} 
            onChange={(e)=> checkInput(e)}
            required 
            />
        </div>
        <div className="form-group">
          <label>
             Address <span>*</span>
          </label>
          <input
            type="text"
            id="address1"
            className="mb--15"
            placeholder="House number and street name"
            value={address1} onChange={(e)=>setAddress1(e.target.value)} name="address1" required />
        </div>
        

        
        <div className="form-group">
          <label>Other Notes (optional)</label>
          <textarea
            id="notes"
            rows="2"
            placeholder="Notes about your order, e.g. speacial notes for delivery."
            value={other_notes} onChange={(e)=>setOther_notes(e.target.value)} name="other_notes"  ></textarea>
        </div>

               </div>
               <div className="col-lg-6">
               <div className="axil-order-summery order-checkout-summery">
               <h5 className="title mb--20">Your Order</h5>
               <div className="summery-table-wrap">
                 <table className="table summery-table">
                   <thead>
                     <tr>
                       <th>Product</th>
                       <th>Subtotal</th>
                     </tr>
                   </thead>
                   <tbody>
                     {items.map((cart) => (
                       <tr className="order-product">
                         <td>
                           {cart.name}{" "}
                           <span className="quantity">
                              {cart.quantity}
                           </span>
                         </td>
                         <td>
                           <span className="currency-symbol">INR. </span>
                           {cart.price}
                         </td>
                       </tr>
                     ))}
                     <tr className="order-subtotal">
                       <td>Subtotal</td>
                       <td>
                         <span className="currency-symbol">INR. </span>
                         {cartTotal}
                       </td>
                     </tr>
                     { charge.cod_charge > 0 &&
                       <tr className="order-subtotal">
                         <td>Delivery Charge</td>
                         <td>
                           <span className="currency-symbol">INR. </span>
                           {charge.cod_charge}
                         </td>
                       </tr>
                     }
                     { couponDiscount > 0 &&
                       <tr className="order-tax">
                           <td>Discount</td>
                           <td> INR.-{couponDiscount} <i className="fal fa-times" onClick={() => removeCoupon()}></i></td>
                           
                       </tr>
                     }
                   <tr className="order-total">
                       <td>Total</td>
                       
                       <td className="order-total-amount">INR. {casht }</td>
                       
                       
                   </tr>
                   { charge == '' &&
                     <tr className="order-total">
                       <td colSpan="2">
                         <p style={{color:"red",textAlign:"center"}}>Please enter pincode first.</p>
                       </td>                            
                     </tr>
                   }
                         
                     <tr className="order-shipping">
                       <td colspan="2"></td>
                     </tr>
                   </tbody>
                 </table>
               </div>
               <div className="order-payment-method">
                 <div class="single-payment" >
                   { charge.isCOD == 1 &&
                     <div class="input-group">
                       <input type="radio" id="radio5" name="payment" onClick={(e)=>setPayment(e.target.value)} value='cod' checked/>
                       <label for="radio5">Cash on delivery</label>
                     </div>
                   }
                   { charge.isCOD == 1 &&
                     <p>Pay with cash upon delivery.</p>
                   }
                   
                   { charge.isCOD == 1 &&
                   <div class="input-group">
                     <input type="radio" id="radio6" name="payment" onClick={(e)=>setPayment(e.target.value)} value='online' />
                     <label for="radio6">Pay online</label>
                   </div>

                   }
                   
                 </div>
               </div>
               {  charge.isCOD === 1 && 
                 <p>sorry we are does not delivery </p>
               }

               {
                 charge.isCOD == 0 &&
                 <p className="sorry">sorry we are does not delivery </p>
               }
               { charge.isCOD == 1 &&
               <button type="submit"   className="axil-btn btn-bg-primary checkout-btn">Payment Process</button>
               }
              
             </div>               


               </div>
        </div>
        </div>
        </form>
        </div>
        {/* <!-- End Checkout Area  --> */}
      </main>

      <ServiceArea />
      <Footer />
    </>
  );
}
export default CheckOut ;


