import React, { Component } from 'react';
import axios from 'axios';
class Pincode  extends Component {
    constructor(props) {
      super(props);
      this.state = {
        codCharge: "",
        codAvilable: "",
        error: ""
        
        ,
      };
      let user = JSON.parse(localStorage.getItem('user'));
      this.state.pincode = user.pincode;
      if(user.pincode){
        this.setState({error: ""});
        axios
          .get(`http://jnkitsolutions.com/findla-backend/api.php?service=pincode&pincode=${user.pincode}`)
          .then(res =>
            this.props.parentCallback(res.data.pincode[0])
          )
          .then(() => {
            document.getElementById("pincode").classList.remove("error");
            this.setState({error: ""});
          })
          .catch(err => {
            document.getElementById("pincode").className = "error";
            this.setState({
              error: "Invalid PIN Code"
            });
            this.props.parentCallback('')
          });
      if (user.pincode !== 6) {
        this.setState({
          city: "",
          state: "",
          error: "ZIP code must be of 6 digits"
        });
      }
      }
    }
    onChange(e) {
      this.setState({ [e.target.name]: e.target.value });
      // if (e.target.value.length === 5 || e.target.value.length === 6) {
        this.setState({error: ""});
        axios
          .get(`http://jnkitsolutions.com/findla-backend/api.php?service=pincode&pincode=${e.target.value}`)
          .then(res =>
            this.props.parentCallback(res.data.pincode[0])
          )
          .then(() => {
            document.getElementById("pincode").classList.remove("error");
            this.setState({error: ""});
          })
          .catch(err => {
            document.getElementById("pincode").className = "error";
            this.setState({
              error: "Invalid PIN Code"
            });
            this.props.parentCallback('')
          });
      if (e.target.value.length !== 6) {
        this.setState({
          city: "",
          state: "",
          error: "ZIP code must be of 6 digits"
        });
      }
    }
    

    render() {
      return (
        <div style={this.props.Container} class="outer_box">       
          <div style={this.props.pincodeContainer} class="group">
            <input type='number'
              maxLength="6"
              minLength="6"
              pattern = "[0-9]"
 
              onChange={e => this.onChange(e)}
              name="pincode"
              placeholder=" "
              value={this.state.pincode}
              id="pincode"
              
              style={this.props.pincodeInput}
              class="pin"
              required
            />{this.state.error ? (
                <span className="error-display">{this.state.error}</span>
              ) : null}
            <span class="highlight"></span>
            <label>Pin code</label>
          </div>
          <div style={this.props.cityContainer} class="group">
          </div>
          <div style={this.props.stateContainer} class="group">            
          </div>
         
        </div>
      );
    }
  }
  
  export default Pincode
  

  
  