import axios from "axios";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
function SignUp() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [disable, setDisable] = useState(false);
  const navigate = useNavigate();

  let handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setDisable(true);
      var form = new FormData();
      form.append("service", "register");
      form.append("name", name);
      form.append("email", email);
      form.append("password", password);
      form.append("phone", phone);
      await axios({
        method: "POST",
        url: "http://jnkitsolutions.com/findla-backend/api.php",
        data: form,
        headers: {
          "Content-Type": `multipart/form-data`,
        },
      })
        .then(function(response) {
          setMessage(response.ack_msg);
          setName("");
          setEmail("");
          setPassword("");
          setMessage("");
          setPhone("");
          if (response.data.ack === 1) {
            swal("Thank you!", response.data.ack_msg, "success");
            localStorage.setItem("user", JSON.stringify(response.data.user));
            navigate("/");
          } else {
            swal(response.data.ack_msg);
          }
        })
        .catch(function(response) {
          setMessage(response.ack_msg);
        });
    } catch (err) {
      setMessage(err);
    }
  };
  const checkInput = (e) =>{
    const onlyDigits = e.target.value.replace(/\D/g, "");
    setPhone(onlyDigits);
    
  }

//   const image = document.getElementsByTagName("img")[0];

// // remove no-js attribute as we know js has been exectuted
// image.removeAttribute("no-js");

// // grab the image src before we rewrite it
// const fullSrc = image.src;

// // rewrite the image src with the placeholder base64 string
// image.src = image.getAttribute("placeholder");

// // create a new image
// const fullImage = new Image();

// // assign the saved src to the new image to preload it
// fullImage.src = fullSrc;

// // wait for the full image to load
// fullImage.onload = function() {
//   // add a small timeout to allow the transition when the image is already in memory
//   setTimeout(() => {
//     // replace the placeholder src with the full image src
//     image.src = fullSrc;
//     // remove the placeholder attribute to trigger the css transition
//     image.removeAttribute("placeholder");
//   }, 200);
// };


  
 
  
  // const onSubmit = (data) => {
  //   console.log({data});
  // };

  return (
    <>
      <div className="axil-signin-area">
        {/* <!-- Start Header --> */}
        <div className="signin-header">
          <div className="row align-items-center">
            <div className="col-md-6">
              <Link to="/" className="site-logo">
                <img src="images/logo.png" alt="logo" />
              </Link>
            </div>
            <div className="col-md-6">
              <div className="singin-header-btn">
                <p>Already a member?</p>
                <Link
                  to="/Signin"
                  className="axil-btn btn-bg-secondary sign-up-btn"
                >
                  Sign In
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- End Header --> */}

        <div className="row">
          <div className="col-xl-4 col-lg-6">
            <div className="axil-signin-banner bg_image bg_image--10">
              <h3 className="title">We Offer the Best Products</h3>
            </div>
          </div>
          <form className="col-lg-6 offset-xl-2" onSubmit={handleSubmit}>
            <div className="axil-signin-form-wrap">
              <div className="axil-signin-form">
                <h3 className="title">I'm New Here</h3>
                <p className="b2 mb--55">Enter your detail below</p>
                <div className="singin-form">
                  <div className="form-group">
                    <label>User Name <span>*</span></label>
                    <input
                      type="text"
                      className="form-control"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      name="name"
                      placeholder="Please Enter Your Name"
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label>Email <span>*</span></label>
                    <input
                      type="email"
                      className="form-control"
                      value={email}
                      pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
                      title="please enter valid Email"
                      onChange={(e) => setEmail(e.target.value)}
                      name="email"
                      placeholder="Ex: Example123@gmail.com"
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label>Password <span>*</span></label>
                    <input
                      type="password"
                      className="form-control"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      name="password"
                      placeholder="Please Enter Your Password"
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label>Phone <span>*</span></label>
                    <input
                    type="phone"
                    minLength= "10"
                    maxLength="10"
                    value={phone}
                    // pattern="\d{10}"
                      className="form-control"
                     
                      // onChange={(e) => setPhone(e.target.value)}
                      onChange={(e)=> checkInput(e)} 
                      // title="please enter correct phone number"
                      name="contact-phone"
                      id="contact-phone"
                      // name="phone"
                      placeholder="Please Enter Your phone number"
                      required
                    />
                  </div>
                  <div className="form-group">
                    <button
                      type="submit"
                      className="axil-btn btn-bg-primary submit-btn" 
                      disabled={disable}
                    >
                      Create Account
                    </button>
                  </div>
                  <div className="message">
                    {message ? <p>{message}</p> : null}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
export default SignUp;
