
import React from 'react'
import { Route, Routes } from 'react-router-dom';
import SignUp from './componet/SignUp';
import SignIn from './componet/SignIn';
import Contact from './componet/Contact';
import Myaccount from './componet/Myaccount';
import Aboutas from './componet/Aboutas';
import Productview from './componet/Productview';
import Cards from './componet/Cards'
import Home1 from './componet/Home1'
import './App.css'
import Product from './componet/Product';
import CheckOut from './componet/CheckOut';
import Findla from './componet/Findla';
import Pincode from './componet/Pincode';
import Faq from './componet/Faq';
import Privacy_Policy from './componet/Privacy_Policy';
import Terms_Of_Use from './componet/Terms_Of_Use';
import Shipping_Returns from './componet/Shipping_Returns';
import Header from './componet/Header';
import 'react-notifications/lib/notifications.css';
import Forgot from './componet/Forgot';
import Otp from './componet/Otp';
import Error from './componet/Error';
import{useState, useEffect} from 'react';

function App() {
   
  

  return (
    <>
    
      <Routes> 
        <Route  path='/' element={<Findla />}></Route>
        <Route path='/Productview/:id' element={<Productview />}></Route>
        <Route path='/Myaccount' element={<Myaccount />}></Route>
        <Route path='/SignIn' element={<SignIn />}></Route>
        <Route path='/CheckOut' element={<CheckOut />}></Route>
        <Route path='/Product' element={<Product />}></Route>
        <Route path='/SignUp' element={<SignUp />}></Route>
        <Route path='/Contact' element={<Contact />}></Route>
        <Route path='/Aboutas' element={<Aboutas />}></Route>
        <Route path='/Cards' element={<Cards />}></Route>
        <Route path='/Pincode' element={<Pincode/>}></Route>
        <Route path='/Home1' element={<Home1 />}></Route>
        <Route path='/Header' element={<Header/>}></Route>
        <Route path='/Faq' element={<Faq/>}/>
        <Route path='/Privacy_Policy' element={<Privacy_Policy/>}/>
        <Route path='/Terms_Of_Use' element={<Terms_Of_Use/>}/>
        <Route path='/Shipping_Returns' element={<Shipping_Returns/>} />
        <Route path='/Forgot' element={<Forgot/>} />
        <Route path='/Otp' element={<Otp/>} />
        <Route path='/*' element={<Error/>}></Route>
      </Routes>
      
    </>
  );
}

export default App;
