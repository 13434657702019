import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom'
import {useNavigate} from 'react-router-dom'

export default function Footer() {
    const[user,setUser]=useState([]);

    useEffect(() => {
        const userDetail = JSON.parse(localStorage.getItem('user'));
        if (userDetail) {
            setUser(userDetail);
        }
        if(!userDetail){
            setUser('')
        }
    }, []);

    
    
  return (
    <>
            <footer className="axil-footer-area footer-style-2">
        {/* <!-- Start Footer Top Area  --> */}
        <div className="footer-top separator-top">
            <div className="container">
                <div className="row">
                    
                    <div className="col-lg-3 col-sm-6">
                        <div className="axil-footer-widget">
                            <h5 className="widget-title">Support</h5>
                            
                            <div className="inner">
                                <p>Atal Incubation Center, Shed 5, <br/>
                                Gujarat TechnologicalUniversity , <br/>
                                  Visat-Gandhinagar, Road,<br/>
                                  Chandkheda, Ahmedabad <br/>
                                  Gujarat 382424
                                </p>
                                <ul className="support-list-item">
                                <li><a href="mailto:care@findla.com"><i class="fal fa-envelope-open"></i> care@findla.com</a></li>
                                    <li><a href="tel:(+91)093161 60270"><i className="fal fa-phone-alt"></i> (+91) 093161 60270</a></li>
                                    
                                </ul>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-lg-3 col-sm-6">
                        <div className="axil-footer-widget">
                            <h5 className="widget-title"></h5>
                            <div className="inner">
                                <ul>
                                    {user &&
                                        <li><Link to="/Myaccount">My Account</Link></li>
                                    }
                                    <li><Link to="/Product">Product</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-lg-3 col-sm-6">
                        <div className="axil-footer-widget">
                            <h5 className="widget-title">Quick Link</h5>
                            <div className="inner">
                                <ul>
                                    <li><Link to="/Privacy_Policy">Privacy Policy</Link></li>
                                    <li><Link to="/Terms_Of_Use">Terms Of Use</Link></li>
                                   <li><Link to="/Faq">FAQ</Link></li>
                                    <li><Link to="/Contact">Contact</Link></li>
                                    
                                </ul>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-lg-3 col-sm-6">
                        <div className="axil-footer-widget">
                            <h5 className="widget-title">MAP</h5>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d3669.7443451358567!2d72.59081956408711!3d23.10645275696352!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sAtal%20Incubation%20Center%2C%20Shed%205%2C%20!5e0!3m2!1sen!2sin!4v1667900290734!5m2!1sen!2sin" width="300" height="200"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
        {/* <!-- End Footer Top Area  --> */}
        {/* <!-- Start Copyright Area  --> */}
        <div className="copyright-area copyright-default separator-top">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-xl-4">
                        <div className="social-share">
                            <Link to="#"><i className="fab fa-facebook-f"></i></Link>
                            <Link to="#"><i className="fab fa-instagram"></i></Link>
                            <Link to="#"><i className="fab fa-twitter"></i></Link>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-12">
                        <div className="copyright-left d-flex flex-wrap justify-content-center">
                            <ul className="quick-link">
                                <li>© 2022. All rights reserved by <a target="_blank" href="https://axilthemes.com/"></a>Findla</li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-12">
                        <div className="copyright-right d-flex flex-wrap justify-content-xl-end justify-content-center align-items-center">
                            <span className="card-text">Accept For</span>
                            <ul className="payment-icons-bottom quick-link">
                                <li><img src="images/cart-1.png" alt="paypal cart"/></li>
                                <li><img src="images/cart-2.png" alt="paypal cart"/></li>
                                <li><img src="images/cart-5.png" alt="paypal cart"/></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- End Copyright Area  --> */}
    </footer>
    </>
  )
}
