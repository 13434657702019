import React, { useEffect, useState } from "react";
import AxilNewsletter from "./AxilNewsletter";
import Footer from "./Footer";
import axios from "axios";
import swal from "sweetalert";
import Header from "./Header";
import Accordion from "react-bootstrap/Accordion";
import Pagination from "./Pagination";
import { Link ,useParams} from "react-router-dom";
import { useCart } from "react-use-cart";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-lazy-load-image-component/src/effects/blur.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import{ PlaceholderImage} from "./placeholder.png";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Image, useImage } from 'react-img-placeholder'


const BASE_URL = 'http://jnkitsolutions.com/findla-backend/api.php?service=add_cart';

export default function Product(cart, setCart) {
  const [isImage, setIsImageLoaded] = useState(false);
  const [product, setProduct] = useState([]);
  const [mobile, setmobile] = useState("");
  const [loading, setLoading] = useState(false);
  const [counter, setCounter] = useState(1);
  const [message, setMessage] = useState("");
  const { items,addItem } = useCart();
  const addNewItem = (item) => {
    NotificationManager.success(
      "Item added successfully",
      "Item added successfully"
    );
    
    addItem(item);
  
  };


  const { emptyCart } = useCart();
  const[name,setName]=useState("");
  const [data, setData] = useState([]);

  const url = "http://jnkitsolutions.com/findla-backend/api.php?service=product";
  useEffect(() => {
    setLoading(true);
    fetch(url)
      .then((response) => response.json())
      .then((json) => {
        setData(json.category);
        setProduct(json.category[0].product);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  }, []);

  const changeCategory = (e) => {
   
    setProduct(data[e].product);
  
  };

  const [showPerPage, setShowPerPage] = useState(15);
  const [pagination, setPagination] = useState({

    start: 0,
    end: showPerPage,

  });

  const onPaginationChange = (start, end) => {

    setPagination({ start: start, end: end });

  };

  const Message = ({ variant, children }) => {
    // the alert is displayed by default
    const [alert, setAlert] = useState(true);

    useEffect(() => {
      // when the component is mounted, the alert is displayed for 3 seconds
      setTimeout(() => {
        setAlert(false);
      }, 3000);
    }, []);
  };
  // console.log(PlaceholderImage);




  // let handleSubmit = async (e) => {
  //   e.preventDefault();
  //   try {
  //     var form = new FormData();
  //     form.append("service", "inquiry");
  //     form.append("message", message);

  //     await axios({
  //       method: "POST",
  //       url: "http://jnkitsolutions.com/findla-backend/api.php",
  //       data: form,
  //       headers: {
  //         "Content-Type": `multipart/form-data`,
  //       },
  //     }).then(function(response) {
  //       setMessage(response.ack_msg);
       
  //       setMessage("");
  //       swal("Thank you!", "Your Form is Submitted!", "success");
  //       // }).catch(function (response) {
  //       //     setMessage(response.ack_msg);
  //     });
  //   } catch (err) {
  //     setMessage(err);
  //   }
  // };

  

  return (
    <>
      <Header />

      {loading ? (
        <div className="loader-containers">
          <div className="spinner"></div>
        </div>
      ) : (
        <div className="sticky-header overflow-md-visible">
          <Link to="#top" className="back-to-top" id="backto-top">
            <i className="fal fa-arrow-up" />
          </Link>
          <main className="main-wrapper">
            <div className="axil-breadcrumb-area">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-lg-6 col-md-8">
                    <div className="inner">
                      <ul className="axil-breadcrumb">
                        <li className="axil-breadcrumb-item">
                          <Link to="/">Home</Link>
                        </li>
                        <li className="separator" />
                        <li
                          className="axil-breadcrumb-item active"
                          aria-current="page"
                        >
                          Product
                        </li>
                      </ul>
                      <h1 className="title">Explore All Products</h1>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-4">
                    <div className="inner">
                      <div className="bradcrumb-thumb">
                        <img src="images/product-45.png" alt="Image" />
                      </div>
                    </div>
                  </div>
                  <div class="d-lg-none">
                    <button class="product-filter-mobile filter-toggle">
                      <i class="fas fa-filter"></i> FILTER
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="axil-shop-area bg-color-white">
              <div className="container">
                <div className="row">
                  <div className="col-lg-3">
                    <div className="axil-shop-sidebar">
                      <div className="d-lg-none">
                        <button className="sidebar-close filter-close-btn">
                          <i className="fas fa-times" />
                        </button>
                      </div>
                      <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                          <div className="toggle-list product-categories dfd">
                            <Accordion.Header className="acco">
                              CATEGORIES
                            </Accordion.Header>
                            <Accordion.Body>
                              <div id="myDIV">
                                <ul>
                                  {data.map((cat, catindex) => {
                                    return (
                                      <div>
                                        <li class="active">
                                          <button
                                            class="btn colorr"
                                            id="active"
                                            onClick={() => {
                                              changeCategory(catindex);
                                            }}
                                          >
                                            {cat.name}
                                          </button>
                                        </li>
                                      </div>
                                    );
                                  })}
                                </ul>
                              </div>
                            </Accordion.Body>
                          </div>
                        </Accordion.Item>
                      </Accordion>
                    </div>
                  </div>

                  <div className="col-lg-9">
                    <div className="row row--15">
                      {product
                        .slice(pagination.start, pagination.end)
                        .map((item) => {
                          return (
                            <div className="col-xl-4 col-sm-6">
                              <div className="axil-product product-style-one mb--30">
                                <div className="thumbnail">
                                  <Link to={`/Productview/${item.id}`}>
                                  <Image
                                  src={item.image_url}
                                  width={255}
                                  height={255}
                                  PlaceholderSrc={PlaceholderImage}
                                  />
                                
                                  </Link>
                                  <div className="product-hover-action">
                                    <ul className="cart-action">
                                      <li className="select-option">
                                        <div>
                                          <span style={{ display: "none" }}>
                                            {(item.price = item.sell_price)}
                                          </span>
                                          <button
                                            className="verify-btn axil-btn btn-bg-secondary"
                                            onClick={() => addNewItem(item)}
                                          >
                                            {" "}
                                            Add to Cart
                                          </button>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div className="product-content">
                                  <div className="inner">
                                    <h5 className="title">
                                      <Link
                                        to="#"
                                        style={{
                                          display: "inline-block",
                                          width: "210px",
                                          whiteSpace: "nowrap",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                        }}
                                      >
                                        {item.name}
                                      </Link>
                                    </h5>
                                    <div className="product-price-variant">
                                      <span className="price current-price">
                                        {item.sell_price}
                                      </span>
                                      <span className="price old-price">
                                        {item.max_price}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}

                      <Pagination
                        showPerPage={showPerPage}
                        onPaginationChange={onPaginationChange}
                        total={product.length}
                      />
                      {product.length <= 0 && (
                        <div className="col-xl-12 col-sm-6 product-blank">
                          No Product available
                        </div>
                      )}
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <AxilNewsletter />
          </main>
        </div>
      )}
      <NotificationContainer />
      <Footer />
    </>
  );
}
