import React from "react";
import { Link } from "react-router-dom";
import { useCart } from "react-use-cart";
import { useEffect, useState, useNavigate } from "react";
import Header from "./Header";
import Header1 from "./Header1";

function Findla() {
  const { totalItems } = useCart();

  return (
    <>
      <body
          className="body"
          style={{
          margin: "0",
          minHeight: "100%",
          fontFamily: "Arial,sans-serif",
          fontSize: "14px",
          lineHeight: "20px",
          color: "#333",
          backgroundColor: "#e01a1a",
        }}
      >
        <Header1></Header1>
        <div className="section wf-section">
          <div
            data-w-id="d87b5922-45d0-1f5b-1087-82cb94efb749"
            className="hero"
          >
            <div className="hero__wrapper is--mobile">
              <div className="hero__container">
                <div
                  data-w-id="1d9bcbfd-3359-0581-f4b7-fb5f72194bf0"
                  className="rotate__page__load"
                >
                  <div className="rotate__page__load__wrapper">
                    <div className="rotate__images">
                      <div
                        data-w-id="1d9bcbfd-3359-0581-f4b7-fb5f72194bf2"
                        className="rotate__images__wrapper__3"
                      >
                        <img
                          src="images/finda-500.png"
                          loading="lazy"
                          srcSet="images/finda-500.png 500w, images/finda-500.png 800w, images/finda-500.png 1080w, images/finda-500.png 1200w"
                          sizes="(max-width: 479px) 145px, 100vw"
                          alt=""
                          className="image is--blood--orange"
                        />
                      </div>
                      <div
                        data-w-id="1d9bcbfd-3359-0581-f4b7-fb5f72194bf4"
                        className="rotate__images__wrapper__2"
                      >
                        <img
                          src="images/finda-500.png"
                          loading="lazy"
                          srcSet="images/finda-500.png 500w, images/finda-500.png 800w, images/finda-500.png 1080w, images/finda-500.png 1200w"
                          sizes="(max-width: 479px) 145px, 100vw"
                          alt=""
                          className="image is--blueberry"
                        />
                      </div>
                      <div
                        data-w-id="1d9bcbfd-3359-0581-f4b7-fb5f72194bf6"
                        className="rotate__images__wrapper__1"
                      >
                        <img
                          src="images/finda-500.png"
                          loading="lazy"
                          srcSet="images/finda-500.png 500w, images/finda-500.png 800w, images/finda-500.png 1080w, images/finda-500.png 1200w"
                          sizes="(max-width: 479px) 145px, 100vw"
                          alt=""
                          className="image is--strawberry"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="rotate__container">
                  <div className="rotate__container__content">
                    <div className="rotate__content__wrapper">
                      {/* <img
                        src="images/6257a2905e696268745eb750_Add%20a%20heading%20%283200%20%C3%97%20900%20px%29.png"
                        loading="lazy"
                        data-w-id="1d9bcbfd-3359-0581-f4b7-fb5f72194bfb"
                        srcSet="images/6257a2905e696268745eb750_Add%20a%20heading%20%283200%20%C3%97%20900%20px%29-p-500.png 500w, images/6257a2905e696268745eb750_Add%20a%20heading%20%283200%20%C3%97%20900%20px%29-p-800.png 800w, images/6257a2905e696268745eb750_Add%20a%20heading%20%283200%20%C3%97%20900%20px%29-p-1080.png 1080w, images/6257a2905e696268745eb750_Add%20a%20heading%20%283200%20%C3%97%20900%20px%29-p-1600.png 1600w, images/6257a2905e696268745eb750_Add%20a%20heading%20%283200%20%C3%97%20900%20px%29-p-2000.png 2000w, images/6257a2905e696268745eb750_Add%20a%20heading%20%283200%20%C3%97%20900%20px%29-p-2600.png 2600w, 3200w"
                        sizes="(max-width: 479px) 97vw, 100vw"
                        alt=""
                        className="hero__heading"
    />*/}
                      <img
                        src="images/6257a2905e696268745eb750_Add.png"
                        loading="lazy"
                        data-w-id="1d9bcbfd-3359-0581-f4b7-fb5f72194bfb"
                        srcSet="images/6257a2905e696268745eb750_Add-p-500.png 500w, images/6257a2905e696268745eb750_Add-p-800.png 800w, images/6257a2905e696268745eb750_Add-p-1080.png 1080w, images/6257a2905e696268745eb750_Add-p-1600.png 1600w, images/6257a2905e696268745eb750_Add-p-2000.png 2000w, images/6257a2905e696268745eb750_Add-p-2600.png 2600w, 3200w"
                        sizes="(max-width: 479px) 97vw, 100vw"
                        alt=""
                        className="hero__heading"
                      />
                    </div>
                    <div className="rotate__content__wrapper is--second">
                      <div
                        data-w-id="1d9bcbfd-3359-0581-f4b7-fb5f72194c03"
                        className="hero__paragraph"
                      >
                        OPUNTIA VULGARIS FRUIT EXTRACT (PRICKLY PEAR - NAGPHANI)
                      </div>
                      <div className="hero__button is--hero">
                        <Link
                          to="/product"
                          data-w-id="e1c12960-8cf2-882a-04da-57ba8e812cf1"
                          className="quote__wrapper w-inline-block"
                        >
                          <div className="button-face">
                            <div className="button-text-3">Shop Now</div>
                          </div>
                          <div className="button-side" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="hero__wrapper is--desktop">
              <div
                data-w-id="9d1c2c63-7ab5-0efa-b932-7c5d36a68dbb"
                className="rotate__page__load"
              >
                <div className="rotate__images">
                  <div className="rotate__images__wrapper__3">
                    <img
                      src="images/finda-500.png"
                      loading="lazy"
                      srcSet="images/finda-500.png 500w, images/finda-500.png 800w, images/finda-500.png 1080w, images/finda-500.png 1200w"
                      sizes="(max-width: 479px) 100vw, (max-width: 991px) 225px, (max-width: 1439px) 300px, 400px"
                      alt=""
                      className="image is--blood--orange"
                    />
                  </div>
                  <div
                    data-w-id="9d1c2c63-7ab5-0efa-b932-7c5d36a68dbf"
                    className="rotate__images__wrapper__2"
                  >
                    <img
                      src="images/finda-500.png"
                      loading="lazy"
                      srcSet="images/finda-500.png 500w, images/finda-500.png 800w, images/finda-500.png 1080w, images/finda-500.png 1200w"
                      sizes="(max-width: 479px) 100vw, (max-width: 991px) 225px, (max-width: 1439px) 300px, 400px"
                      alt=""
                      className="image is--blueberry"
                    />
                  </div>
                  <div
                    data-w-id="9d1c2c63-7ab5-0efa-b932-7c5d36a68dc1"
                    className="rotate__images__wrapper__1"
                  >
                    <img
                      src="images/finda-500.png"
                      loading="lazy"
                      srcSet="images/finda-500.png 500w, images/finda-500.png 800w, images/finda-500.png 1080w, images/finda-500.png 1200w"
                      sizes="(max-width: 479px) 100vw, (max-width: 991px) 225px, (max-width: 1439px) 300px, 400px"
                      alt=""
                      className="image is--strawberry"
                    />
                  </div>
                </div>
              </div>
              <div className="rotate__container">
                <div className="rotate__container__content">
                  <div className="rotate__content__wrapper">
                    <img
                      src="images/6257a2905e696268745eb750_Add.png"
                      loading="lazy"
                      data-w-id="9d1c2c63-7ab5-0efa-b932-7c5d36a68dc6"
                      srcSet="images/6257a2905e696268745eb750_Add-p-500.png 500w, images/6257a2905e696268745eb750_Add-p-800.png 800w, images/6257a2905e696268745eb750_Add-p-1080.png 1080w, images/6257a2905e696268745eb750_Add-p-1600.png 1600w, images/6257a2905e696268745eb750_Add-p-2000.png 2000w, images/6257a2905e696268745eb750_Add-p-2600.png 2600w, images/6257a2905e696268745eb750_Add.png 3200w"
                      sizes="(max-width: 479px) 100vw, (max-width: 991px) 400px, (max-width: 1439px) 600px, (max-width: 1919px) 750px, 800px"
                      alt=""
                      className="hero__heading"
                    />
                  </div>
                  <div className="rotate__content__wrapper is--second">
                    <div
                      data-w-id="9d1c2c63-7ab5-0efa-b932-7c5d36a68dc8"
                      className="hero__paragraph"
                    >
                      OPUNTIA VULGARIS FRUIT EXTRACT (PRICKLY PEAR - NAGPHANI)
                    </div>
                    <div className="hero__button">
                      <Link
                        to="/Product"
                        data-w-id="61a6456a-7af3-7bf4-5303-b0a1c3afbde6"
                        className="quote__wrapper w-inline-block"
                      >
                        <div className="button-face">
                          <div className="button-text-3">Shop Now</div>
                        </div>
                        <div className="button-side" />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section wf-section">
          <div
            data-w-id="a26df410-fc6f-446d-697b-120baf265f43"
            className="benefits is--2"
          >
            <div className="the__first__wrapper">
              <div className="the__first__container w-container">
                <div className="the__first__heading__wrapper">
                  <h1 className="the__first__heading">
                    The <span className="text-span">first</span>
                  </h1>
                </div>
                <div className="of__its__kind__wrapper">
                  <h1 className="heading-4">
                    Of its <span className="text-span-2">kind</span>
                  </h1>
                </div>
                <div className="the__first__paragraph">
                  <div className="the__first__paragraph__wrapper">
                    <p className="paragraph">
                      Rogue&amp;Rosy is changing up the jam industry with its
                      brand new alcohol infused fruit jam.
                    </p>
                  </div>
                </div>
              </div>
              <div className="arrow__lottie__animation">
                <div
                  data-is-ix2-target={1}
                  className="lottie__arrow"
                  data-w-id="fb447cc2-64f7-67e8-874a-08e685bd2048"
                  data-animation-type="lottie"
                  data-src="https://assets.website-files.com/6255f45b057f538067d930f1/6256cba3aff349263c805b8d_lf30_editor_mz4r1thi%20(1).json"
                  data-loop={0}
                  data-direction={1}
                  data-autoplay={0}
                  data-renderer="svg"
                  data-default-duration={2}
                  data-duration={0}
                />
              </div>
            </div>
            <div className="benefits__image__container">
              <div className="benefit__image__wrapper">
                <div
                  data-w-id="220ca332-b49b-0499-2417-39f4a1692832"
                  className="smiley__face__wrapper"
                >
                  <img
                    src={
                      process.env.REACT_APP_FRONTEND_URL +
                      "images/6256558290c44700ef34ebaa_Untitled.png"
                    }
                    loading="lazy"
                    srcSet="images/6256558290c44700ef34ebaa_Untitled-p-500.png 500w, images/6256558290c44700ef34ebaa_Untitled-p-800.png 800w, images/6256558290c44700ef34ebaa_Untitled-p-1080.png 1080w, images/6256558290c44700ef34ebaa_Untitled.png 1200w"
                    sizes="(max-width: 1919px) 75px, 100px"
                    alt=""
                    className="smiley__face"
                  />
                </div>
                <div className="benefits__images_2">
                  <img
                    src="images/sugar.gif"
                    loading="lazy"
                    alt=""
                    className="benefits__image is--desktop"
                  />
                  <img
                    src="images/625710162587ced8eba0d82c_Untitled%20design.gif"
                    loading="lazy"
                    alt=""
                    className="benefits__image is--tablet"
                  />
                </div>
                <div className="mobile__benefits__image__wraper">
                  <img
                    src="images/625710162587ced8eba0d82c_Untitled%20design.gif"
                    loading="lazy"
                    alt=""
                    className="benefits__image is--mobile"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section is--products wf-section">
          <div className="products is--desktop">
            <div className="div-block-69">
              <div className="products__flavors__text__wrapper">
                <div className="products__flavor__text__position">
                  <div
                    id="Products"
                    data-w-id="bb098cae-7965-9d15-4358-a5227adf69d7"
                    className="products__flavors__text__layout"
                  >
                    <h1
                      style={{
                        WebkitTransform:
                          "translate3d(20px, 10px, 0) scale3d(1.2, 1, 1) rotateX(-15deg) rotateY(0) rotateZ(6deg) skew(-15deg, 26deg)",
                        MozTransform:
                          "translate3d(20px, 10px, 0) scale3d(1.2, 1, 1) rotateX(-15deg) rotateY(0) rotateZ(6deg) skew(-15deg, 26deg)",
                        msTransform:
                          "translate3d(20px, 10px, 0) scale3d(1.2, 1, 1) rotateX(-15deg) rotateY(0) rotateZ(6deg) skew(-15deg, 26deg)",
                        transform:
                          "translate3d(20px, 10px, 0) scale3d(1.2, 1, 1) rotateX(-15deg) rotateY(0) rotateZ(6deg) skew(-15deg, 26deg)",
                        transformStyle: "preserve-3d",
                        opacity: 0,
                      }}
                      className="products__heading"
                    >
                      Boozy Jam
                    </h1>
                    <h1 className="products__heading is--flavors">Flavors</h1>
                    <h1 className="products__heading is--flavors paragraph-2">
                      3 Fantastic Flavors
                    </h1>
                  </div>
                </div>
              </div>
              <div className="product__flavors__fixed__scroll__layout">
                <div id="home_color-black" className="flavor__slide is--first">
                  <div className="flavor__background" />
                  <div className="facts__wrapper">
                    <div className="facts__content">
                      <div className="facts__container">
                        <div className="facts__icon">
                          <img
                            src="images/62565d89ad6cf22f51d29d78_Untitled%20design%20%2844%29.png"
                            loading="lazy"
                            srcSet="images/62565d89ad6cf22f51d29d78_Untitled%20design%20%2844%29-p-500.png 500w, images/62565d89ad6cf22f51d29d78_Untitled%20design%20%2844%29.png 700w"
                            sizes="(max-width: 991px) 100vw, (max-width: 1439px) 50px, (max-width: 1919px) 3vw, 50px"
                            alt=""
                            className="image-8"
                          />
                        </div>
                        <div className="facts__text">
                          <p className="paragraph-3">Strengthes Immunity</p>
                        </div>
                      </div>
                    </div>
                    <div className="facts__content">
                      <div className="facts__container">
                        <div className="facts__icon">
                          <img
                            src="images/62565fa917b31be593ed5cfa_Untitled%20design%20%2845%29.png"
                            loading="lazy"
                            srcSet="images/62565fa917b31be593ed5cfa_Untitled%20design%20%2845%29-p-500.png 500w, images/62565fa917b31be593ed5cfa_Untitled%20design%20%2845%29.png 700w"
                            sizes="(max-width: 991px) 100vw, (max-width: 1439px) 50px, (max-width: 1919px) 3vw, 50px"
                            alt=""
                            className="image-8"
                          />
                        </div>
                        <div className="facts__text">
                          <p className="paragraph-3">Helps Natural Formation of Blood</p>
                        </div>
                      </div>
                    </div>
                    <div className="facts__content">
                      <div className="facts__container">
                        <div className="facts__icon">
                          <img
                            src="images/62565fa93b4937aaec090236_Untitled%20design%20%2846%29.png"
                            loading="lazy"
                            srcSet="images/62565fa93b4937aaec090236_Untitled%20design%20%2846%29-p-500.png 500w, images/62565fa93b4937aaec090236_Untitled%20design%20%2846%29.png 700w"
                            sizes="(max-width: 991px) 100vw, (max-width: 1439px) 50px, (max-width: 1919px) 3vw, 50px"
                            alt=""
                            className="image-8"
                          />
                        </div>
                        <div className="facts__text">
                          <p className="paragraph-3">Increases RBC, WBC & Platelet Count</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  id="home_color-orange"
                  className="flavor__slide is--second"
                >
                  <div className="flavor__background watch_orange" />
                  <div className="facts__wrapper">
                    <div className="facts__content">
                      <div className="facts__container">
                        <div>
                          <img
                            src="images/62565d89ad6cf22f51d29d78_Untitled%20design%20%2844%29.png"
                            loading="lazy"
                            srcSet="images/62565d89ad6cf22f51d29d78_Untitled%20design%20%2844%29-p-500.png 500w, images/62565d89ad6cf22f51d29d78_Untitled%20design%20%2844%29.png 700w"
                            sizes="(max-width: 991px) 100vw, (max-width: 1439px) 50px, (max-width: 1919px) 3vw, 50px"
                            alt=""
                            className="image-8"
                          />
                        </div>
                        <div className="facts__text">
                          <p className="paragraph-3">Strengthes Immunity</p>
                        </div>
                      </div>
                    </div>
                    <div className="facts__content">
                      <div className="facts__container">
                        <div>
                          <img
                            src="images/62565fa917b31be593ed5cfa_Untitled%20design%20%2845%29.png"
                            loading="lazy"
                            srcSet="images/62565fa917b31be593ed5cfa_Untitled%20design%20%2845%29-p-500.png 500w, images/62565fa917b31be593ed5cfa_Untitled%20design%20%2845%29.png 700w"
                            sizes="(max-width: 991px) 100vw, (max-width: 1439px) 50px, (max-width: 1919px) 3vw, 50px"
                            alt=""
                            className="image-8"
                          />
                        </div>
                        <div className="facts__text">
                          <p className="paragraph-3">Helps Natural Formation of Blood</p>
                        </div>
                      </div>
                    </div>
                    <div className="facts__content">
                      <div className="facts__container">
                        <div>
                          <img
                            src="images/62565fa93b4937aaec090236_Untitled%20design%20%2846%29.png"
                            loading="lazy"
                            srcSet="images/62565fa93b4937aaec090236_Untitled%20design%20%2846%29-p-500.png 500w, images/62565fa93b4937aaec090236_Untitled%20design%20%2846%29.png 700w"
                            sizes="(max-width: 991px) 100vw, (max-width: 1439px) 50px, (max-width: 1919px) 3vw, 50px"
                            alt=""
                            className="image-8"
                          />
                        </div>
                        <div className="facts__text">
                          <p className="paragraph-3">Increases RBC, WBC & Platelet Count</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flavor__slide is--third">
                  <div
                    id="home_color-navy"
                    className="flavor__background watch_navy"
                  />
                  <div className="facts__wrapper">
                    <div className="facts__content">
                      <div className="facts__container">
                        <div>
                          <img
                            src="images/62565d89ad6cf22f51d29d78_Untitled%20design%20%2844%29.png"
                            loading="lazy"
                            srcSet="images/62565d89ad6cf22f51d29d78_Untitled%20design%20%2844%29-p-500.png 500w, images/62565d89ad6cf22f51d29d78_Untitled%20design%20%2844%29.png 700w"
                            sizes="(max-width: 991px) 100vw, (max-width: 1439px) 50px, (max-width: 1919px) 3vw, 50px"
                            alt=""
                            className="image-8"
                          />
                        </div>
                        <div className="facts__text">
                          <p className="paragraph-3">Strengthes Immunity</p>
                        </div>
                      </div>
                    </div>
                    <div className="facts__content">
                      <div className="facts__container">
                        <div>
                          <img
                            src="images/62565fa917b31be593ed5cfa_Untitled%20design%20%2845%29.png"
                            loading="lazy"
                            srcSet="images/62565fa917b31be593ed5cfa_Untitled%20design%20%2845%29-p-500.png 500w, images/62565fa917b31be593ed5cfa_Untitled%20design%20%2845%29.png 700w"
                            sizes="(max-width: 991px) 100vw, (max-width: 1439px) 50px, (max-width: 1919px) 3vw, 50px"
                            alt=""
                            className="image-8"
                          />
                        </div>
                        <div className="facts__text">
                          <p className="paragraph-3">Helps Natural Formation of Blood</p>
                        </div>
                      </div>
                    </div>
                    <div className="facts__content">
                      <div className="facts__container">
                        <div>
                          <img
                            src="images/62565fa93b4937aaec090236_Untitled%20design%20%2846%29.png"
                            loading="lazy"
                            srcSet="images/62565fa93b4937aaec090236_Untitled%20design%20%2846%29-p-500.png 500w, images/62565fa93b4937aaec090236_Untitled%20design%20%2846%29.png 700w"
                            sizes="(max-width: 991px) 100vw, (max-width: 1439px) 50px, (max-width: 1919px) 3vw, 50px"
                            alt=""
                            className="image-8"
                          />
                        </div>
                        <div className="facts__text">
                          <p className="paragraph-3">Increases RBC, WBC & Platelet Count</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section is--mobile wf-section">
          <div className="product__flavors">
            <div
              id="Products-Mobile"
              className="products__flavors__text__wrapper"
            >
              <div className="products__flavor__text__position">
                <div
                  data-w-id="a9f19421-e680-7147-d9a3-8b756501cd6f"
                  className="products__flavors__text__layout"
                >
                  <h1
                    style={{
                      WebkitTransform:
                        "translate3d(20px, 10px, 0) scale3d(1.2, 1, 1) rotateX(-15deg) rotateY(0) rotateZ(6deg) skew(-15deg, 26deg)",
                      MozTransform:
                        "translate3d(20px, 10px, 0) scale3d(1.2, 1, 1) rotateX(-15deg) rotateY(0) rotateZ(6deg) skew(-15deg, 26deg)",
                      msTransform:
                        "translate3d(20px, 10px, 0) scale3d(1.2, 1, 1) rotateX(-15deg) rotateY(0) rotateZ(6deg) skew(-15deg, 26deg)",
                      transform:
                        "translate3d(20px, 10px, 0) scale3d(1.2, 1, 1) rotateX(-15deg) rotateY(0) rotateZ(6deg) skew(-15deg, 26deg)",
                      transformStyle: "preserve-3d",
                      opacity: 0,
                    }}
                    className="products__heading"
                  >
                    Flavors
                  </h1>
                  <div
                    style={{
                      WebkitTransform:
                        "translate3d(20px, 10px, 0) scale3d(1.2, 1, 1) rotateX(-15deg) rotateY(0) rotateZ(6deg) skew(-15deg, 26deg)",
                      MozTransform:
                        "translate3d(20px, 10px, 0) scale3d(1.2, 1, 1) rotateX(-15deg) rotateY(0) rotateZ(6deg) skew(-15deg, 26deg)",
                      msTransform:
                        "translate3d(20px, 10px, 0) scale3d(1.2, 1, 1) rotateX(-15deg) rotateY(0) rotateZ(6deg) skew(-15deg, 26deg)",
                      transform:
                        "translate3d(20px, 10px, 0) scale3d(1.2, 1, 1) rotateX(-15deg) rotateY(0) rotateZ(6deg) skew(-15deg, 26deg)",
                      transformStyle: "preserve-3d",
                      opacity: 0,
                    }}
                    className="flavors__text__paragraph__wrapper"
                  >
                    <p className="instagram__handle">3 Fantastic Flavors</p>
                  </div>
                  <div className="hero__button is--products"></div>
                </div>
              </div>
            </div>
            <div className="product__flavors__fixed__scroll__layout__mobile">
              <div
                id="home_color-black"
                className="product__flavors__slide is--first"
              >
                <div className="product__flavors__slide__background">
                  <img
                    src="images/6256e5b8efb6f64885cbdd79_3.png"
                    loading="lazy"
                    srcSet="images/6256e5b8efb6f64885cbdd79_3.png 500w, images/6256e5b8efb6f64885cbdd79_3.png 800w, images/6256e5b8efb6f64885cbdd79_3.png 1080w, images/6256e5b8efb6f64885cbdd79_3.png 1201w"
                    sizes="100vw"
                    alt=""
                    className="product__flavors__image"
                  />
                </div>
              </div>
              <div id="home_color-orange" className="product__flavors__slide">
                <div className="product__flavors__slide__background watch_orange">
                  <img
                    src="images/6256e5b7f395024f3de94112_2.png"
                    loading="lazy"
                    srcSet="images/6256e5b7f395024f3de94112_2.png 500w, images/6256e5b7f395024f3de94112_2.png 800w, images/6256e5b7f395024f3de94112_2.png 1080w, images/6256e5b7f395024f3de94112_2.png 1201w"
                    sizes="100vw"
                    alt=""
                    className="product__flavors__image"
                  />
                </div>
              </div>
              <div className="product__flavors__slide">
                <div
                  id="home_color-navy"
                  className="product__flavors__slide__background watch_navy"
                >
                  <img
                    src="images/6256e5b83c5c1ca274cf89d3_1.png"
                    loading="lazy"
                    srcSet="images/6256e5b83c5c1ca274cf89d3_1.png 500w, images/6256e5b83c5c1ca274cf89d3_1.png 800w, images/6256e5b83c5c1ca274cf89d3_1.png 1080w, images/6256e5b83c5c1ca274cf89d3_1.png 1201w"
                    sizes="100vw"
                    alt=""
                    className="product__flavors__image"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="section wf-section">
          <div className="testimonials is--mobile">
            <div className="testimonial__wrapper">
              <div className="testimonial__container">
                <div
                  data-w-id="4c5b52fb-c967-861f-0bd1-24f1035d7aec"
                  className="products__flavors__text__layout is--seenin"
                >
                  <h1 className="products__heading is--seenin">As seen in</h1>
                  <div
                    style={{
                      WebkitTransform:
                        "translate3d(20px, 10px, 0) scale3d(1.2, 1, 1) rotateX(-15deg) rotateY(0) rotateZ(6deg) skew(-15deg, 26deg)",
                      MozTransform:
                        "translate3d(20px, 10px, 0) scale3d(1.2, 1, 1) rotateX(-15deg) rotateY(0) rotateZ(6deg) skew(-15deg, 26deg)",
                      msTransform:
                        "translate3d(20px, 10px, 0) scale3d(1.2, 1, 1) rotateX(-15deg) rotateY(0) rotateZ(6deg) skew(-15deg, 26deg)",
                      transform:
                        "translate3d(20px, 10px, 0) scale3d(1.2, 1, 1) rotateX(-15deg) rotateY(0) rotateZ(6deg) skew(-15deg, 26deg)",
                      transformStyle: "preserve-3d",
                      opacity: 0,
                    }}
                    className="flavors__text__paragraph__wrapper"
                  />
                </div>
                <div className="w-layout-grid testimonial__grid">
                  <div
                    id="w-node-a4b8448c-bb95-9a66-4dfa-b3bfb310cab7-0042cbe1"
                    className="feature__testimonial"
                  >
                    <img
                      src="images/625783e3e1b46b7ed1f98eac_3.png"
                      loading="lazy"
                      srcSet="images/625783e3e1b46b7ed1f98eac_3-p-500.png 500w, images/625783e3e1b46b7ed1f98eac_3.png 600w"
                      sizes="(max-width: 479px) 200px, 100vw"
                      alt=""
                      className="brand__logo__testimonial is--people"
                    />
                    <a
                      href="#"
                      className="quote__wrapper is--button--1 w-inline-block"
                    >
                      <div className="button-face-3">
                        <div className="button-text">
                          "An amazing, all natural, organic jam for parties and
                          events."
                        </div>
                      </div>
                      <div className="button-side-3 is--button--1" />
                    </a>
                  </div>
                  <div className="feature__testimonial is--feature--card--2">
                    <img
                      src="images/625783e3b40607a6785e3d5c_5.png"
                      loading="lazy"
                      srcSet="images/625783e3b40607a6785e3d5c_5-p-500.png 500w, images/625783e3b40607a6785e3d5c_5.png 600w"
                      sizes="(max-width: 479px) 200px, 100vw"
                      alt=""
                      className="brand__logo__testimonial is--cosmo"
                    />
                    <a
                      href="#"
                      className="quote__wrapper is--button--2 w-inline-block"
                    >
                      <div className="button-face-3">
                        <div className="button-text">
                          "An amazing, all natural, organic jam for parties and
                          events."
                        </div>
                      </div>
                      <div className="button-side-3 is--button--2" />
                    </a>
                  </div>
                  <div className="feature__testimonial">
                    <img
                      src="images/625783e38745d8da6742a97a_1.png"
                      loading="lazy"
                      srcSet="images/625783e38745d8da6742a97a_1-p-500.png 500w, images/625783e38745d8da6742a97a_1.png 600w"
                      sizes="(max-width: 479px) 200px, 100vw"
                      alt=""
                      className="brand__logo__testimonial is--buzzfeed"
                    />
                    <a
                      href="#"
                      className="quote__wrapper is--button--3 w-inline-block"
                    >
                      <div className="button-face-3">
                        <div className="button-text">
                          "An amazing, all natural, organic jam for parties and
                          events."
                        </div>
                      </div>
                      <div className="button-side-3 is--button--3" />
                    </a>
                  </div>
                  <div className="feature__testimonial is--feature--card--4">
                    <img
                      src="images/625783e37cb3834b51ba250e_4.png"
                      loading="lazy"
                      srcSet="images/625783e37cb3834b51ba250e_4-p-500.png 500w, images/625783e37cb3834b51ba250e_4.png 600w"
                      sizes="(max-width: 479px) 200px, 100vw"
                      alt=""
                      className="brand__logo__testimonial is--foodwine"
                    />
                    <a
                      href="#"
                      className="quote__wrapper is--button-4 w-inline-block"
                    >
                      <div className="button-face-3">
                        <div className="button-text">
                          "An amazing, all natural, organic jam for parties and
                          events."
                        </div>
                      </div>
                      <div className="button-side-3 is--button-4" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="testimonials is--desktop">
            <div className="testimonial__wrapper">
              <div className="testimonial__container">
                <div className="instagram__title__wrapper is--seen">
                  <div className="instagram__title">As seen in</div>
                </div>
                <div className="w-layout-grid testimonial__grid">
                  <div
                    id="w-node-_00139be4-6db8-761e-3962-d6c6bcac38bf-0042cbe1"
                    data-w-id="00139be4-6db8-761e-3962-d6c6bcac38bf"
                    className="feature__testimonial"
                  >
                    <img
                      src="images/625783e3e1b46b7ed1f98eac_3.png"
                      loading="lazy"
                      srcSet="images/625783e3e1b46b7ed1f98eac_3-p-500.png 500w, images/625783e3e1b46b7ed1f98eac_3.png 600w"
                      sizes="(max-width: 479px) 100vw, 200px"
                      alt=""
                      className="brand__logo__testimonial is--people"
                    />
                    <a
                      href="#"
                      className="quote__wrapper is--button--1 w-inline-block"
                    >
                      <div className="button-face-3">
                        <div className="button-text">
                          "An amazing, all natural, organic jam for parties and
                          events."
                        </div>
                      </div>
                      <div className="button-side-3 is--button--1" />
                    </a>
                  </div>
                  <div
                    data-w-id="0b4c1feb-ed2e-9cc1-f800-764b4817b33a"
                    className="feature__testimonial is--feature--card--2"
                  >
                    <img
                      src="images/625783e3b40607a6785e3d5c_5.png"
                      loading="lazy"
                      srcSet="images/625783e3b40607a6785e3d5c_5-p-500.png 500w, images/625783e3b40607a6785e3d5c_5.png 600w"
                      sizes="(max-width: 479px) 100vw, 200px"
                      alt=""
                      className="brand__logo__testimonial is--cosmo"
                    />
                    <a
                      href="#"
                      className="quote__wrapper is--button--2 w-inline-block"
                    >
                      <div className="button-face-3">
                        <div className="button-text">
                          "An amazing, all natural, organic jam for parties and
                          events."
                        </div>
                      </div>
                      <div className="button-side-3 is--button--2" />
                    </a>
                  </div>
                  <div
                    data-w-id="eeb89c0e-b60c-fdbd-590f-4cf3b8dd7269"
                    className="feature__testimonial"
                  >
                    <img
                      src="images/625783e38745d8da6742a97a_1.png"
                      loading="lazy"
                      srcSet="images/625783e38745d8da6742a97a_1-p-500.png 500w, images/625783e38745d8da6742a97a_1.png 600w"
                      sizes="(max-width: 479px) 100vw, 200px"
                      alt=""
                      className="brand__logo__testimonial is--buzzfeed"
                    />
                    <a
                      href="#"
                      className="quote__wrapper is--button--3 w-inline-block"
                    >
                      <div className="button-face-3">
                        <div className="button-text">
                          "An amazing, all natural, organic jam for parties and
                          events."
                        </div>
                      </div>
                      <div className="button-side-3 is--button--3" />
                    </a>
                  </div>
                  <div
                    data-w-id="8d86be53-b28e-59e9-ca8b-4de100871bf7"
                    className="feature__testimonial is--feature--card--4"
                  >
                    <img
                      src="images/625783e37cb3834b51ba250e_4.png"
                      loading="lazy"
                      srcSet="images/625783e37cb3834b51ba250e_4-p-500.png 500w, images/625783e37cb3834b51ba250e_4.png 600w"
                      sizes="(max-width: 479px) 100vw, 200px"
                      alt=""
                      className="brand__logo__testimonial is--foodwine"
                    />
                    <a
                      href="#"
                      className="quote__wrapper is--button-4 w-inline-block"
                    >
                      <div className="button-face-3">
                        <div className="button-text">
                          "An amazing, all natural, organic jam for parties and
                          events."
                        </div>
                      </div>
                      <div className="button-side-3 is--button-4" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section wf-section">
          <div
            data-w-id="3eb75a64-ec4f-062b-bf87-6052328e9f8b"
            className="subscription is--desktop"
          >
            <div className="subscription__wrapper">
              <div className="subscription__container">
                <div className="first__subscription__heading__wrapper">
                  <div className="delivered__container">
                    <h1 className="heading-7" style={{ fontSize: "5em" }}>
                      Delivered
                    </h1>
                  </div>
                  <div className="every__month__container">
                    <h1 className="heading-7" style={{ fontSize: "5em" }}>
                      <span className="text-span-4">Every Month</span>
                    </h1>
                  </div>
                </div>
                <div className="second__subscription__heading__wrapper">
                  <h1 className="heading-7" style={{ fontSize: "5em" }}>
                    to your door step
                  </h1>
                </div>
                <div className="subscribe__button is--sub">
                  <Link to="product" className="quote__wrapper w-inline-block">
                    <div className="button-face">
                      <div className="button-text-3">Buy Now</div>
                    </div>
                    <div className="button-side" />
                  </Link>
                </div>
              </div>
              <div className="subscription__box__wrapper">
                <img
                  src="images/62586d9e444734d6a9ea99bc.png"
                  loading="lazy"
                  srcSet="images/62586d9e444734d6a9ea99bc.png 500w, images/62586d9e444734d6a9ea99bc.png 800w, images/62586d9e444734d6a9ea99bc.png 1080w, images/62586d9e444734d6a9ea99bc.png 1500w"
                  sizes="(max-width: 479px) 100vw, (max-width: 991px) 400px, (max-width: 1919px) 650px, 1100px"
                  alt=""
                  className="subscription__box"
                />
              </div>
            </div>
          </div>
          <div
            data-w-id="749c45b6-9d78-fb98-d963-901a86f8458d"
            className="subscription is--mobile"
          >
            <div className="subscription__wrapper">
              <div className="subscription__container">
                <div className="first__subscription__heading__wrapper">
                  <div className="delivered__container">
                    <h1 className="heading-7">Delivered</h1>
                  </div>
                  <div className="every__month__container">
                    <h1 className="heading-7">
                      <span className="text-span-3">Monthly to</span>
                    </h1>
                  </div>
                </div>
                <div className="second__subscription__heading__wrapper">
                  <h1 className="heading-7">your doorstep</h1>
                </div>
                <div className="hero__button">
                  <Link to="product" className="quote__wrapper w-inline-block">
                    <div className="button-face is--subscribe">
                      <div className="button-text-3">Buy Now</div>
                    </div>
                    <div className="button-side" />
                  </Link>
                </div>
              </div>
              <div className="div-block-59">
                <div className="subscription_box_wrapper">
                  <img
                    src="images/62586d9e444734d6a9ea99bc.png"
                    loading="lazy"
                    srcSet="images/62586d9e444734d6a9ea99bc.png 500w, images/62586d9e444734d6a9ea99bc.png 800w, images/62586d9e444734d6a9ea99bc.png 1080w, images/62586d9e444734d6a9ea99bc.png 1500w"
                    sizes="(max-width: 479px) 97vw, 100vw"
                    alt=""
                    className="subscription_box_image"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="section wf-section">
          <div
            data-w-id="56f11759-f0ef-ade4-41b1-6e1cc13fd9c8"
            className="our__story our--story is--mobile"
          >
            <div className="our__story__container">
              <div className="our__story__layout">
                <div className="our__story__heading__content">
                  <div className="our__story__text">Rogue&amp;Rosy's</div>
                  <h1 className="our__story__heading">
                     <span className="text-span-5">Our  story</span>
                  </h1>
                  <div className="our__story__text__paragraph">
                    Started by two jam lovers.
                  </div>
                </div>
                <div className="our__story__images__wrapper">
                  <div className="visuals__one">
                    <div className="visual__wrapper">
                      <div
                     
                        className="red__visiual__background"
                      />
                      <img
                        src="images/6258b4f0380afe46eb1b2cf0_Untitled%20design%20-%202022-04-14T195613.118.png"
                        loading="lazy"
                        srcSet="images/6258b4f0380afe46eb1b2cf0_Untitled%20design%20-%202022-04-14T195613.118-p-500.png 500w, images/6258b4f0380afe46eb1b2cf0_Untitled%20design%20-%202022-04-14T195613.118.png 570w"
                        sizes="(max-width: 479px) 224px, 100vw"
                        alt=""
                        className="visual__image"
                      />
                    </div>
                  </div>
                  <div className="visuals__two">
                    <div className="visual__wrapper">
                      <div
                       
                        className="red__visiual__background"
                      />
                      <img
                        src="images/6258b1dbe2983f837618ce55_22.png"
                        loading="lazy"
                        srcSet="images/6258b1dbe2983f837618ce55_22-p-500.png 500w, images/6258b1dbe2983f837618ce55_22.png 640w"
                        sizes="(max-width: 479px) 210px, 100vw"
                        alt=""
                        className="visual__image"
                      />
                    </div>
                  </div>
                  <div className="visuals__three">
                    <div className="visual__wrapper">
                      <div
                       
                        className="red__visiual__background"
                      />
                      <img
                        src="images/6258b4f0efabee1e2ff24bfb_Untitled%20design%20-%202022-04-14T195636.444.png"
                        loading="lazy"
                        srcSet="images/6258b4f0efabee1e2ff24bfb_Untitled%20design%20-%202022-04-14T195636.444-p-500.png 500w, images/6258b4f0efabee1e2ff24bfb_Untitled%20design%20-%202022-04-14T195636.444-p-800.png 800w, images/6258b4f0efabee1e2ff24bfb_Untitled%20design%20-%202022-04-14T195636.444.png 1044w"
                        sizes="(max-width: 479px) 95vw, 100vw"
                        alt=""
                        className="visual__image"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            data-w-id="3e6667e9-93eb-a8f6-a37e-30974cdd23bb"
            className="our__story our--story is--desktop"
          >
            <div className="our__story__container">
              <div className="our__story__layout">
                <div className="our__story__heading__content">
                  <div
                    data-w-id="c264ad56-99e4-8298-6039-9c41f075a1bf"
                    style={{
                      opacity: 0,
                      WebkitTransform:
                        "translate3d(null, 1em, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      MozTransform:
                        "translate3d(null, 1em, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      msTransform:
                        "translate3d(null, 1em, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      transform:
                        "translate3d(null, 1em, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    }}
                    className="our__story__text"
                  >
                    Rogue&amp;Rosy's
                  </div>
                  <h1
                    data-w-id="c264ad56-99e4-8298-6039-9c41f075a1c1"
                    style={{
                      opacity: 0,
                      WebkitTransform:
                        "translate3d(0, 0.5em, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      MozTransform:
                        "translate3d(0, 0.5em, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      msTransform:
                        "translate3d(0, 0.5em, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      transform:
                        "translate3d(0, 0.5em, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    }}
                    className="our__story__heading"
                  >
                    Our <span className="text-span-5">story</span>
                  </h1>
                  <div
                    data-w-id="c264ad56-99e4-8298-6039-9c41f075a1c3"
                    style={{
                      WebkitTransform:
                        "translate3d(0, 1em, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      MozTransform:
                        "translate3d(0, 1em, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      msTransform:
                        "translate3d(0, 1em, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      transform:
                        "translate3d(0, 1em, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      opacity: 0,
                    }}
                    className="our__story__text__paragraph"
                  >
                    Started by two jam lovers.
                  </div>
                </div>
                <div className="our__story__images__wrapper">
                  <div className="visuals__one">
                    <div className="visual__wrapper">
                      <div className="red__visiual__background" />
                      <img
                        src="images/6258a0bb99b891a7f960ab60_25.png"
                        loading="lazy"
                        srcSet="images/6258a0bb99b891a7f960ab60_25-p-500.png 500w, images/6258a0bb99b891a7f960ab60_25-p-800.png 800w, images/6258a0bb99b891a7f960ab60_25-p-1080.png 1080w, images/6258a0bb99b891a7f960ab60_25.png 1200w"
                        sizes="(max-width: 767px) 100vw, (max-width: 991px) 280px, (max-width: 1919px) 420px, 630px"
                        alt=""
                        className="visual__image"
                      />
                    </div>
                  </div>
                  <div className="visuals__two">
                    <div className="visual__wrapper">
                      <div className="red__visiual__background" />
                      <img
                        src="images/6258a013bc8303f9c7956685_22.png"
                        loading="lazy"
                        srcSet="images/6258a013bc8303f9c7956685_22-p-500.png 500w, images/6258a013bc8303f9c7956685_22-p-800.png 800w, images/6258a013bc8303f9c7956685_22-p-1080.png 1080w, images/6258a013bc8303f9c7956685_22.png 1200w"
                        sizes="(max-width: 479px) 100vw, (max-width: 767px) 619.7734375px, (max-width: 991px) 308px, (max-width: 1919px) 378px, 630px"
                        alt=""
                        className="visual__image"
                      />
                    </div>
                  </div>
                  <div className="visuals__three">
                    <div className="visual__wrapper">
                      <div className="red__visiual__background" />
                      <img
                        src="images/62589f18c57a87bbe64fc058_14.png"
                        loading="lazy"
                        srcSet="images/62589f18c57a87bbe64fc058_14-p-500.png 500w, images/62589f18c57a87bbe64fc058_14-p-800.png 800w, images/62589f18c57a87bbe64fc058_14-p-1080.png 1080w, images/62589f18c57a87bbe64fc058_14.png 1200w"
                        sizes="(max-width: 767px) 100vw, (max-width: 991px) 51vw, (max-width: 1919px) 507.5px, 711.625px"
                        alt=""
                        className="visual__image"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="section wf-section">
          <div className="instagram">
            <div className="instagram__title__wrapper is--insta">
              <div className="instagram__title">Our Insta</div>
              <div className="instagram__handle__wrapper">
                <p className="instagram__handle is--insta--handle">
                  @rogueandrosy
                </p>
              </div>
            </div>
            <div>
              <div className="insta__image__wrapper">
                <img
                  src="images/our_insta.png"
                  loading="lazy"
                  srcSet="images/our_insta.png 500w, images/our_insta.png 800w, images/our_insta.png 1080w, images/our_insta.png 1600w, images/our_insta.png 2000w, images/our_insta.png 2500w"
                  sizes="(max-width: 479px) 100vw, (max-width: 991px) 95vw, (max-width: 2777px) 90vw, 2500px"
                  alt=""
                  className="insta__photos is--desktop"
                />
                <img
                  src="images/our_insta_mobile.png"
                  loading="lazy"
                  srcSet="images/our_insta_mobile.png 500w, images/our_insta_mobile.png 800w, images/our_insta_mobile.png 1080w, images/our_insta_mobile.png 1200w"
                  sizes="100vw"
                  alt=""
                  className="insta__photos is--mobile"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="footer">
          <div className="footer__container">
            <div className="w-layout-grid footer__grid">
              <div>
                <div className="brand__logo is--footer" />
              </div>
              <div className="div-block-67">
                <p className="footer-title">Menu</p>
                <Link to="/Product" className="footer-social">
                  Products
                </Link>
                <Link to="#" className="footer-social">
                  Collections
                </Link>
              </div>
              <div className="div-block-68">
                <p className="footer-title">Company</p>
                <Link to="/Aboutas" className="footer-social">
                  About Us
                </Link>
                <Link to="/Shipping_Returns" className="footer-social">
                  Shipping Returns
                </Link>
                <Link to="/Faq" className="footer-social">
                  FAQ
                </Link>
                <Link to="/Privacy_Policy" className="footer-social">
                  PrivacyPolicy
                </Link>
                <Link to="/Terms_Of_Use" className="footer-social">
                  Terms & Conditions
                </Link>
              </div>
            </div>
            <div className="footer__bottom">
              <div className="footer__detail__block">
                <div className="footer__large__title">
                  Subscribe to our news
                </div>
                <p className="footer__details">
                  The stories you’ll be talking about tomorrow —<br />
                  delivered straight to your inbox today
                </p>
              </div>
            </div>
          </div>
        </div>
      </body>
    </>
  );
}

export default Findla;
