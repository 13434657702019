import axios from "axios";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

const Forgot = () => {
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [message, setMessage] = useState("");
  const [password, setPassword] = useState("");
  const [otpSend, setOtpSend] = useState(false);
  const [disable, setDisable] = useState(false);
  const [passwordChange, setPasswordChange] = useState(false);
  const navigate = useNavigate();

  let handleSubmit = async (e) => {
    //   e.preventDefault();
    try {
      var form = new FormData();
      form.append("service", "forgot_password");
      form.append("email", email);
      await axios({
        method: "POST",
        url: "http://jnkitsolutions.com/findla-backend/api.php",
        data: form,
        headers: {
          "Content-Type": `multipart/form-data`,
        },
      })
        .then(function(response) {
          setMessage(response.ack_msg);
          if (response.data.ack === 1) {
            setOtpSend(true);
            NotificationManager.success(
              response.data.ack_msg,
              response.data.ack_msg
            );
          } else {
            NotificationManager.error(
              response.data.ack_msg,
              response.data.ack_msg
            );
          }
        })
        .catch(function(response) {
          setMessage(response.ack_msg);
        });
    } catch (err) {
      setMessage(err);
    }
  };

  let checkOtp = async (e) => {
    //   e.preventDefault();
    try {
      var form = new FormData();
      form.append("service", "verify_otp");
      form.append("email", email);
      form.append("otp", otp);
      await axios({
        method: "POST",
        url: "http://jnkitsolutions.com/findla-backend/api.php",
        data: form,
        headers: {
          "Content-Type": `multipart/form-data`,
        },
      })
        .then(function(response) {
          setMessage(response.ack_msg);
          if (response.data.ack === 1) {
            NotificationManager.success(
              response.data.ack_msg,
              response.data.ack_msg
            );
            setOtpSend(false);
            setPasswordChange(true);
            // window.location = '/Signin';
          } else {
            NotificationManager.error(
              response.data.ack_msg,
              response.data.ack_msg
            );
          }
        })
        .catch(function(response) {
          setMessage(response.ack_msg);
        });
    } catch (err) {
      setMessage(err);
    }
  };

  let checkPassword = async (e) => {
    //   e.preventDefault();
    try {
      var form = new FormData();
      form.append("service", "new_password");
      form.append("email", email);
      form.append("new_password", password);
      await axios({
        method: "POST",
        url: "http://jnkitsolutions.com/findla-backend/api.php",
        data: form,
        headers: {
          "Content-Type": `multipart/form-data`,
        },
      })
        .then(function(response) {
          setMessage(response.ack_msg);
          if (response.data.ack === 1) {
            NotificationManager.success(
              response.data.ack_msg,
              response.data.ack_msg
            );
            setOtpSend(false);
            setPasswordChange(false);
            setEmail("");
            window.location = "/SignIn";
          } else {
            NotificationManager.error(
              response.data.ack_msg,
              response.data.ack_msg
            );
          }
        })
        .catch(function(response) {
          setMessage(response.ack_msg);
        });
    } catch (err) {
      setMessage(err);
    }
  };

  return (
    <>
      <NotificationContainer />
      <div className="axil-signin-area">
        {/* <!-- Start Header --> */}
        <div className="signin-header">
          <div className="row align-items-center">
            <div className="col-sm-4">
              <Link to="/" className="site-logo">
                <img src="images/logo.png" alt="logo" />
              </Link>
            </div>
            <div className="col-sm-8">
              <div className="singin-header-btn">
                <p>Not a member?</p>
                <Link
                  to="/SignUp"
                  className="axil-btn btn-bg-secondary sign-up-btn"
                >
                  Sign Up Now
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-4 col-lg-6">
            <div className="axil-signin-banner bg_image bg_image--9">
              <h3 className="title">We Offer the Best Products</h3>
            </div>
          </div>
          <div className="col-lg-6 offset-xl-2">
            <div className="axil-signin-form-wrap">
              <div className="axil-signin-form">
                {!passwordChange && (
                  <h3 className="title">Forgot to Findla.</h3>
                )}
                {passwordChange && (
                  <h3 className="title">Change Password to Findla.</h3>
                )}
                <p className="b2 mb--55">Enter your detail below</p>
                {!passwordChange && (
                  <div className="form-group">
                    <label>Email</label>
                    <input
                      type="email"
                      className="form-control"
                      disabled={disable}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      name="email"
                      placeholder="Ex: Example123@gmail.com"
                      required
                      
                    />
                  </div>
                )}
                {otpSend && !passwordChange && (
                  <div className="form-group">
                    <label>Otp</label>
                    <input
                      type="text"
                      className="form-control"
                      value={otp}
                      onChange={(e) => setOtp(e.target.value)}
                      name="otp"
                      placeholder="Enter your otp"
                      required
                    />
                  </div>
                )}
                {passwordChange && (
                  <div className="form-group">
                    <label>Password</label>
                    <input
                      type="password"
                      className="form-control"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      name="otp"
                      placeholder="Enter your password"
                      required
                    />
                  </div>
                )}
                <div className="form-group d-flex align-items-center justify-content-between">
                  {!otpSend && !passwordChange && (
                    <button
                      type="submit"
                      className="axil-btn btn-bg-primary submit-btn "
                      disabled={disable}
                      onClick={() => {
                        handleSubmit();
                      }}
                    >
                      Submit
                    </button>
                  )}
                  {otpSend && !passwordChange && (
                    <button
                      type="submit"
                      className="axil-btn btn-bg-primary submit-btn"
                      onClick={() => {
                        checkOtp();
                      }}
                    >
                      Verify Otp
                    </button>
                  )}
                  {passwordChange && (
                    <button
                      type="submit"
                      className="axil-btn btn-bg-primary submit-btn"
                      onClick={() => {
                        checkPassword();
                      }}
                    >
                      Change Password
                    </button>
                  )}
                  <Link to="/Signin" className="forgot-btn">
                    Sign in
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Forgot;
